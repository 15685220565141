import AppTheme from "./AppTheme";
import AppThemeCoal from "./AppThemeCoal";
import AppThemeAngeloni from "./AppThemeAngeloni";
import { GET } from "request-helper";
import AppThemeVimar from "./AppThemeVimar";
import AppThemeAmodioPPM from "./AppThemeAmodioPPM";

const themeFactory = async (fromWeb) => {
  if (fromWeb) {
    var promise = GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Backoffice/GetTemplateCss",
      null,
      null,
      true
    );
    return promise;
  } else {
    switch (process.env.REACT_APP_THEME) {
      case "coal":
        return AppThemeCoal;
      case "angeloni":
        return AppThemeAngeloni;
      case "vimar":
        return AppThemeVimar;
      case "amodioppm":
        return AppThemeAmodioPPM;
      default:
        return AppTheme;
    }
  }
};

export default themeFactory;
