import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Link from "@material-ui/core/Link";

const Logo = (props) => {
  const props_getSizeOfLogo = props.getSizeOfLogo || function () {};

  return (
    // <Link
    //   href="#"
    //   onClick={() => {
    //     props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
    //   }}
    // >
    <div>
      {props.store.AppReducers && props.store.AppReducers.SpoCfg && (
        <img
          onLoad={function (e) {
            props_getSizeOfLogo({
              w: e.currentTarget.width,
              h: e.currentTarget.height,
              direction:
                e.currentTarget.width > e.currentTarget.height ? "h" : "v",
              square:
                e.currentTarget.width / e.currentTarget.height < 2
                  ? false
                  : true,
            });
          }}
          style={{ width: "100%" }}
          // src={process.env.REACT_APP_BASE_PATH_ROUTE + "/logo/Logo-Angeloni.png"}
          src={
            props.store.AppReducers.SpoCfg.logoImage.indexOf("http") == 0
              ? props.store.AppReducers.SpoCfg.logoImage
              : process.env.REACT_APP_BASE_PATH_ROUTE +
                "/Logo/" +
                props.store.AppReducers.SpoCfg.logoImage
          }
        ></img>
      )}
    </div>
    // </Link>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};

export default withRouter(
  connect(mapStateToProps, {
    // SetInfoCart,
    // SaveStateApp,
  })(Logo)
);
