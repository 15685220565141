import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Box,
  Tabs,
  Tab,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronCircleLeft,
  faCalendar,
  faMapMarkedAlt,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";

import { SetInfoCart } from "../redux/actions";
import LayoutWithHead from "../components/LayoutWithHead";
import Logo from "../components/Logo";
import Footer from "../components/Footer";
import DialogConfirm from "../components/DialogConfirm";
import { roundTwoDigit } from "../utility/utility";

import { GET, PUT, POST } from "request-helper";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogConfirmPayment: {
    color: "#5e2a2b",
  },
}));

const PaymentMode = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [amount, setAmount] = React.useState(0);
  const [couponCode, setCouponCode] = React.useState("");
  const [couponMessage, setCouponMessage] = React.useState("");
  const [couponDescription, setCouponDescription] = React.useState("");
  const [couponObject, setCouponObject] = React.useState(null);
  const [typePayment, setTypePayment] = React.useState("1");

  const [modePayment, setModePayment] = React.useState(0);

  const [deliveryDetail, setDeliveryDetail] = React.useState(null);

  const [dialogCompletePrenotation, setDialogCompletePrenotation] =
    React.useState(null);
  const [confermato, setConfermato] = useState(false);
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChangeIndex = (event, newValue) => {
    setModePayment(newValue);
  };

  const handleChangeRitiro = (event) => {
    setTypePayment(event.target.value);
  };

  const createDeliveryDetail = (data) => {
    var obj = {
      deliveryModeId: data.idDeliveryModeNavigation.id,
      deliveryMode: data.idDeliveryModeNavigation.description,
      dateDelivery: data.webSpoTimeAllocation.dateAllocation,
      timeStart: data.webSpoTimeAllocation.webSpoTimeDefinition.timeStart,
      timeEnd: data.webSpoTimeAllocation.webSpoTimeDefinition.timeEnd,
      shopAddress:
        data.puntoVendita.indir +
        " " +
        data.puntoVendita.loca +
        " " +
        data.puntoVendita.prov,
      deliveryAddress: data.idAddressNavigation
        ? data.idAddressNavigation.address +
          " " +
          data.idAddressNavigation.city +
          " " +
          data.idAddressNavigation.postalCode +
          " " +
          data.idAddressNavigation.province
        : null,
      ShopInfo: data.puntoVendita.desPv,
    };
    setDeliveryDetail(obj);
  };

  //0 consegna
  //1 elettronico

  //0 contanti
  //1 cc/bancomat

  const fetchConfirmCart = (formatPayment) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/ConfirmCart?shopId=" +
        props.store.CartReducers.infoCart.shopId +
        "&enableSimilar=" +
        props.store.CartReducers.infoCart.enableSimilar +
        "&note=" +
        props.store.CartReducers.infoCart.cartNote,
      null,
      null,
      true
    ).then((data) => {
      if (formatPayment != 3) setDialogCompletePrenotation(true);
    });
  };

  const fetchTypePayment = (formatPayment) => {
    return PUT(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Payment/SetPaymentTypeToCart?shopId=" +
        props.store.CartReducers.infoCart.shopId +
        "&idPaymentType=" +
        formatPayment +
        "&couponCode=" +
        couponCode,
      null,
      null,
      true
    );
  };

  const fetchMakePaymentOnline = (formatPayment) => {
    let urlOk =
      window.location.origin +
      process.env.REACT_APP_BASE_PATH_ROUTE +
      "/PaymentOk";
    let urlKo =
      window.location.origin +
      process.env.REACT_APP_BASE_PATH_ROUTE +
      "/PaymentKo";
    return POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Payment/Create",
      {
        shopId: props.store.CartReducers.infoCart.shopId,
        urlOk,
        urlKo,
      },
      null,
      true
    ).then((data) => {
      let loadScript = (url, callback) => {
        var script = document.createElement("script");
        script.src = url;
        script.onload = function () {
          // Chiama la funzione definita nel file
          callback();
        };
        document.body.appendChild(script);
      };

      if (data.redirectLightBox) {
        if (process.env.REACT_APP_ALT_PAYJS == "true") {
          //"https://7340-82-180-35-65.ngrok-free.app/payment-Lightbox.html?sl=GESPAY80262&pid=2035299433565&pt=74c1e534-5ab4-4046-a308-dbd248f5206a"
          let params = new window.URLSearchParams(
            data.redirectLightBox.split("?")[1]
          );
          let shop = params.get("sl");
          let transaction = params.get("pid");
          let token = params.get("pt");
          loadScript(
            //"https://webitshop.mfdata.it/paymentapitest/payment-Lightbox.js", //prod
            //"https://webitshop.mfdata.it/paymentapitest/payment-Lightbox-Sandbox.js", //test
            data.redirectLightBox,
            () => {
              loadScript(window.payJs.urlLibPay, () => {
                //shopId,paymentId,paymentToken
                window.payJs.openDialogPayment(shop, transaction, token);
                setConfermato(false);
              });
            }
          );
          return;
        }
        window.location.href = data.redirectLightBox + "&cancelUrl=" + urlKo;
      }
    });
  };

  const fetchCouponVerify = () => {
    if (!couponCode) return;
    if (props.store.CartReducers == null) return;
    //string code, string shopId, decimal amount
    let param = {
      code: couponCode,
      shopId: props.store.CartReducers.shopId,
      amount: amount,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Payment/VerifyCoupon",
      null,
      param,
      true
    ).then((data) => {
      if (Object.entries(data) == 0) {
        setCouponMessage("Coupon non valido");
        setCouponObject(null);
        return;
      }
      if (data.couponIsUsed) {
        setCouponMessage("Questo coupon risulta essere già utilizzato");
        setCouponObject(null);
        return;
      }
      setCouponMessage("");
      setCouponDescription(data.couponDescription);
      setCouponObject(data);
    });
  };

  useEffect(() => {
    if (!props.store.CartReducers) return;
    setAmount(props.store.CartReducers.total);
    createDeliveryDetail(props.store.CartReducers.cartSummary);
  }, [props.store.CartReducers]);

  useEffect(() => {
    if (couponObject == null) return;
  }, [couponObject]);

  useEffect(() => {
    //pagamento consegna
    if (
      process.env.REACT_APP_PAYMENT_PICKUP == "true" &&
      process.env.REACT_APP_PAYMENT_DIRECT_CC == "false"
    ) {
      setModePayment(1);
    }
    //pagamento online
    if (
      process.env.REACT_APP_PAYMENT_PICKUP == "false" &&
      process.env.REACT_APP_PAYMENT_DIRECT_CC == "true"
    ) {
      setModePayment(0);
    }
  }, []);

  const handleClickProcedi = () => {
    //pagamento consegna
    setConfermato(true);
    switch (modePayment) {
      case 0:
        fetchTypePayment(3).then((data) => {
          if (data.error) return;
          fetchMakePaymentOnline();
          fetchConfirmCart(3);
        });
        break;
      case 1:
        fetchTypePayment(typePayment).then((data) => {
          if (data.error) return;
          fetchConfirmCart();
        });
        break;
    }
  };

  const cartPrenotationCompleted = () => {
    props.SetInfoCart(null);
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
  };

  const tabPayments = () => {};

  return (
    <div className={classes.root}>
      <LayoutWithHead
        rowLogoLeft={<Logo></Logo>}
        rowBar={
          <>
            <Grid item xs={6}>
              {/* Tab menu */}
              <Tooltip title="Torna agli acquisti">
                <IconButton
                  style={{ fontSize: "45px", padding: 0 }}
                  color="primary"
                  onClick={() => {
                    props.history.push(
                      process.env.REACT_APP_BASE_PATH_ROUTE + "/product"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        }
      >
        <Box
          style={{
            backgroundColor: theme.spo.product.backgroundPaper,
            padding: theme.spo.pSm,
            width: "100%",
          }}
        >
          <Grid item xs={12} style={{}}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper style={{ padding: theme.spo.pMd }}>
                      <Box>
                        <h3 style={{ marginTop: 0 }}>CODICE SCONTO </h3>
                        <h4 style={{ marginTop: 0 }}>Hai un codice sconto?</h4>
                      </Box>

                      <TextField
                        error={couponMessage}
                        helperText={couponMessage || couponDescription}
                        value={couponCode}
                        style={{ width: "100%" }}
                        id="standard-required"
                        label="Codice coupon"
                        onChange={(e) => {
                          setCouponCode(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                  setCouponMessage("");
                                  setCouponObject(null);
                                  setCouponCode("");
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </IconButton>
                              <Button
                                // variant="contained"
                                // color="primary"
                                onClick={() => {
                                  fetchCouponVerify();
                                }}
                              >
                                Usa Coupon
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper style={{ padding: theme.spo.pMd }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <h3 style={{ marginTop: 0 }}>MODALITÀ PAGAMENTO </h3>
                          <h4 style={{ marginTop: 0 }}>
                            Seleziona la modalità di pagamento
                          </h4>
                          <Tabs
                            value={modePayment}
                            onChange={handleChangeIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            <Tab
                              disabled={
                                process.env.REACT_APP_PAYMENT_DIRECT_CC ===
                                "false"
                              }
                              label="Online"
                              {...a11yProps(0)}
                              style={{
                                display:
                                  process.env.REACT_APP_PAYMENT_DIRECT_CC ===
                                  "false"
                                    ? "none"
                                    : "inline",
                              }}
                            />

                            <Tab
                              disabled={
                                process.env.REACT_APP_PAYMENT_PICKUP === "false"
                              }
                              label="Al ritiro"
                              {...a11yProps(1)}
                              style={{
                                display:
                                  process.env.REACT_APP_PAYMENT_PICKUP ===
                                  "false"
                                    ? "none"
                                    : "inline",
                              }}
                            />
                          </Tabs>

                          <TabPanel value={modePayment} index={0}>
                            <FontAwesomeIcon
                              icon={["fab", "cc-visa"]}
                              style={{
                                fontSize: 36,
                                paddingRight: theme.spo.pXs,
                              }}
                            />
                            <FontAwesomeIcon
                              icon={["fab", "cc-mastercard"]}
                              style={{
                                fontSize: 36,
                                paddingRight: theme.spo.pXs,
                              }}
                            />
                            <FontAwesomeIcon
                              icon={["fab", "cc-amex"]}
                              style={{
                                fontSize: 36,
                                paddingRight: theme.spo.pXs,
                              }}
                            />
                          </TabPanel>

                          <TabPanel value={modePayment} index={1}>
                            <FormControl component="fieldset">
                              <RadioGroup
                                name="typePayment"
                                value={typePayment}
                                onChange={handleChangeRitiro}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Contanti"
                                />
                                <FormControlLabel
                                  value="2"
                                  control={<Radio />}
                                  label="Carta di credito o bancomat"
                                />
                              </RadioGroup>
                            </FormControl>
                          </TabPanel>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper style={{ padding: theme.spo.pMd }}>
                      <h3 style={{ marginTop: 0 }}>Riepilogo ordine</h3>
                      <Grid container>
                        <Grid
                          container
                          style={{
                            padding: "10px",
                            borderTop: "dotted 1px",
                          }}
                        >
                          <Grid item xs="6">
                            Carrello{" "}
                          </Grid>
                          <Grid item xs="6" style={{ textAlign: "right" }}>
                            {" "}
                            <FormattedNumber
                              value={roundTwoDigit(amount || 0)}
                              style="currency"
                              currency="EUR"
                              currencySign="accounting"
                            />
                          </Grid>
                        </Grid>
                        {couponObject && (
                          <Grid
                            container
                            style={{
                              padding: "10px",
                              borderTop: "dotted 1px",
                            }}
                          >
                            <Grid item xs="6">
                              {couponDescription}
                              {/* Sconto coupon: */}
                            </Grid>
                            <Grid item xs="6" style={{ textAlign: "right" }}>
                              <FormattedNumber
                                value={roundTwoDigit(
                                  couponObject.amountDiscount
                                )}
                                style="currency"
                                currency="EUR"
                                currencySign="accounting"
                              />
                            </Grid>
                          </Grid>
                        )}

                        <Grid
                          container
                          style={{
                            backgroundColor: "#3f51b5",
                            color: "#fff",
                            padding: "10px",
                            ...(theme.spo.secondaryBar || {}),
                          }}
                        >
                          <Grid item xs="6">
                            Totale:{" "}
                          </Grid>
                          <Grid item xs="6" style={{ textAlign: "right" }}>
                            <FormattedNumber
                              value={roundTwoDigit(
                                amount -
                                  (couponObject
                                    ? couponObject.amountDiscount
                                    : 0)
                              )}
                              style="currency"
                              currency="EUR"
                              currencySign="accounting"
                            />
                          </Grid>
                        </Grid>
                        {process.env.REACT_APP_PAYMENT_DIRECT_CC == "true" && (
                          <div style={{ marginTop: theme.spo.mSm }}>
                            L'importo dell'autorizzazione (blocco del plafond o
                            disponibilità della carta) verrà ripristinato dal
                            circuito che ha emesso la sua carta, con tempistiche
                            variabili a seconda del singolo circuito. Solo
                            l'effettivo importo della spesa verrà addebitato.
                          </div>
                        )}
                        <div style={{ width: "100%", textAlign: "right" }}>
                          <DialogConfirm
                            title={"Vuoi confermare il tuo ordine?"}
                            text={
                              <div
                                className={classes.dialogConfirmPayment}
                                style={{ minWidth: "300px" }}
                              >
                                {deliveryDetail && (
                                  <>
                                    <FontAwesomeIcon
                                      icon={faCalendar}
                                      style={{
                                        marginRight: "5px",
                                        float: "left",
                                        marginTop: "2px",
                                      }}
                                    />{" "}
                                    <h4 style={{ marginBottom: 0 }}>
                                      Data, ora consegna
                                    </h4>
                                    <Box>
                                      <Moment format="DD-MM-YYYY">
                                        {deliveryDetail.dateDelivery}
                                      </Moment>{" "}
                                      <Moment
                                        parse="YYYY-MM-DD HH:mm"
                                        format="HH:mm"
                                      >
                                        {deliveryDetail.timeStart}
                                      </Moment>{" "}
                                      -{" "}
                                      <Moment
                                        parse="YYYY-MM-DD HH:mm"
                                        format="HH:mm"
                                      >
                                        {deliveryDetail.timeEnd}
                                      </Moment>
                                    </Box>
                                    <Box>
                                      <FontAwesomeIcon
                                        icon={faShoppingBag}
                                        style={{
                                          marginRight: "5px",
                                          float: "left",
                                          marginTop: "2px",
                                        }}
                                      />{" "}
                                      {deliveryDetail.deliveryMode}
                                    </Box>
                                    {deliveryDetail.deliveryModeId == 1 && (
                                      <>
                                        <Box
                                          style={{ marginTop: theme.spo.mSm }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMapMarkedAlt}
                                            style={{
                                              marginRight: "5px",
                                              float: "left",
                                              marginTop: "2px",
                                            }}
                                          />{" "}
                                          <h4
                                            style={{
                                              marginBottom: 0,
                                              marginTop: 0,
                                            }}
                                          >
                                            Indirizzo ritiro
                                          </h4>
                                        </Box>
                                        <Box>
                                          {
                                            props.store.CartReducers
                                              .puntoVendita.desPv
                                          }
                                        </Box>
                                        <Box>
                                          {
                                            props.store.CartReducers
                                              .puntoVendita.indir
                                          }
                                        </Box>
                                        <Box>
                                          {
                                            props.store.CartReducers
                                              .puntoVendita.loca
                                          }{" "}
                                          (
                                          {
                                            props.store.CartReducers
                                              .puntoVendita.prov
                                          }
                                          )
                                        </Box>
                                      </>
                                    )}
                                    {deliveryDetail.deliveryModeId == 2 && (
                                      <>
                                        <Box
                                          style={{ marginTop: theme.spo.mSm }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMapMarkedAlt}
                                            style={{
                                              marginRight: "5px",
                                              float: "left",
                                              marginTop: "2px",
                                            }}
                                          />{" "}
                                          <h4
                                            style={{
                                              marginBottom: 0,
                                              marginTop: 0,
                                            }}
                                          >
                                            Indirizzo consegna
                                          </h4>
                                        </Box>
                                        <Box>
                                          {deliveryDetail.deliveryAddress}
                                        </Box>
                                      </>
                                    )}
                                    <Grid container>
                                      <Grid item xs="4">
                                        <h4 style={{ marginBottom: 0 }}>
                                          Totale
                                        </h4>
                                      </Grid>
                                      <Grid item xs="8">
                                        <h4 style={{ marginBottom: 0 }}>
                                          Modalità pagamento
                                        </h4>
                                      </Grid>
                                      <Grid item xs="4">
                                        <FormattedNumber
                                          value={roundTwoDigit(
                                            amount -
                                              (couponObject
                                                ? couponObject.amountDiscount
                                                : 0)
                                          )}
                                          style="currency"
                                          currency="EUR"
                                          currencySign="accounting"
                                        />
                                      </Grid>
                                      <Grid item xs="8">
                                        <div>
                                          {modePayment == 1 &&
                                            typePayment == 1 && (
                                              <div>
                                                {deliveryDetail.deliveryModeId ==
                                                  2 && (
                                                  <div>
                                                    Contanti alla consegna
                                                  </div>
                                                )}
                                                {deliveryDetail.deliveryModeId ==
                                                  1 && <div>Contanti</div>}
                                              </div>
                                            )}
                                          {modePayment == 1 &&
                                            typePayment == 2 && (
                                              <div>
                                                {deliveryDetail.deliveryModeId ==
                                                  2 && (
                                                  <div>
                                                    Carta di credito o bancomat
                                                    alla consegna
                                                  </div>
                                                )}
                                                {deliveryDetail.deliveryModeId ==
                                                  1 && (
                                                  <div>
                                                    Carta di credito o bancomat
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          {modePayment == 0 && (
                                            <div>Pagamento online</div>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Box style={{ marginTop: theme.spo.mSm }}>
                                      <FontAwesomeIcon
                                        icon={faMapMarkedAlt}
                                        style={{
                                          marginRight: "5px",
                                          float: "left",
                                          marginTop: "2px",
                                        }}
                                      />{" "}
                                      <h4
                                        style={{
                                          marginBottom: 0,
                                          marginTop: 0,
                                        }}
                                      >
                                        Punto vendita selezionato
                                      </h4>
                                    </Box>
                                    <Box>
                                      {
                                        props.store.CartReducers.puntoVendita
                                          .desPv
                                      }
                                    </Box>
                                    <Box>
                                      {
                                        props.store.CartReducers.puntoVendita
                                          .indir
                                      }
                                    </Box>
                                    <Box>
                                      {
                                        props.store.CartReducers.puntoVendita
                                          .loca
                                      }{" "}
                                      (
                                      {
                                        props.store.CartReducers.puntoVendita
                                          .prov
                                      }
                                      )
                                    </Box>
                                  </>
                                )}
                              </div>
                            }
                          >
                            {(confirm) => (
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  //width: "100%",
                                  marginTop: theme.spo.mSm,
                                }}
                                onClick={confirm(() => {
                                  handleClickProcedi();
                                })}
                                disabled={confermato}
                              >
                                Conferma
                              </Button>
                            )}
                          </DialogConfirm>
                        </div>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </LayoutWithHead>
      <Footer></Footer>
      <Dialog
        onClose={() => {
          setDialogCompletePrenotation(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={dialogCompletePrenotation}
      >
        <DialogTitle id="simple-dialog-title">
          La tua prenotazione è andata a buon fine
        </DialogTitle>
        <DialogContent>
          <div>
            <div>
              Ti verrà inviata una mail di riepilogo dell'ordine. Ti verrà
              inoltre notificato via mail la presa in carico da parte del punto
              vendita.
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            id="cartPaymentCompletedContinue"
            onClick={() => {
              setDialogCompletePrenotation(false);
              cartPrenotationCompleted();
            }}
            color="primary"
          >
            Continua
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};
export default connect(mapStateToProps, { SetInfoCart })(PaymentMode);
