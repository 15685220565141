import React, { useEffect } from "react";
import { UserManager } from "oidc-client";

import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import {
  SetUserLogged,
  SetAppConfigFromServer,
  fetchCfgSpo,
} from "../redux/actions";

import { GET, setBearerToken } from "request-helper";

import { makeStyles } from "@material-ui/core/styles";

import { extendedLoginConfig, loginConfig } from "../loginConfig";

const useStyles = makeStyles((theme) => {});

const Login = (props) => {
  var config = extendedLoginConfig(props.AppReducers);

  const classes = useStyles();

  const [viewModel, setViewModel] = React.useState({
    username: "",
    password: "",
  });

  const [textLoading, setTextLoading] = React.useState("Caricamento...");

  const handleChange = (name) => (event) => {
    setViewModel({ ...viewModel, [name]: event.target.value });
  };

  function checkUserIsRegistered() {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/User/IsRegisteredUser",
      null,
      null,
      true
    ).then((data) => {
      if (data == true) {
        props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
      } else {
        props.history.push(
          process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardUser"
        );
      }
    });
  }

  useEffect(() => {
    //chiamata asincrona alle api per verificare se l'utente è loggato
    if (process.env.REACT_APP_MAINTENANCE == "false") {
      var mgr = new UserManager(config);
      mgr.getUser().then(function (user) {
        if (user != null && user.profile != null && !user.expired) {
          setBearerToken(user.access_token);
          props.fetchCfgSpo();
          checkUserIsRegistered();
          props.SetUserLogged(user);
        } else {
          //mgr.signinRedirect();
          props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/main");
        }
      });
    }
  }, []);

  useEffect(() => {
    let data = props.UserReducers;
    // let data = props;
    if (!data.viewAppCfg) return;
    if (data.viewAppCfg.Status == "401") {
      setTextLoading(
        "Non si hanno le autorizzazioni necessarie per utilizzare l'applicativo"
      );
      return;
    }

    // if (data.viewAppCfg.fUsoViewapp != undefined) return;
    //props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/giornaliero");
    //chiamata asincrona alle api per verificare se l'utente è loggato
  }, [props.UserReducers]);

  return (
    <div>
      <Dialog open={true}>
        <div style={{ margin: "10px" }}>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
          <div>{textLoading}</div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {
  SetUserLogged,
  SetAppConfigFromServer,
  fetchCfgSpo,
})(Login);
