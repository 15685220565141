import { GET, POST } from "request-helper";

import SpoLayoutBg from "../components/SpoLayoutBg";
import Footer from "../components/Footer";
import CustomIcon from "../components/CustomIcon";

import DialogWithContent from "../components/DialogWithContent";

import { connect } from "react-redux";
import store from "../redux/store";
import { SaveStateApp, SetInfoCart, fetchCfgShopSpo } from "../redux/actions";

import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Chip from "@material-ui/core/Chip";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { NotificationManager } from "react-notifications";
import ReactMoment from "react-moment";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import itLocale from "date-fns/locale/it";
import { ThemeProvider } from "@material-ui/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleCarry,
  faTruck,
  faCheck,
  faPlus,
  faClock,
  faCircle,
  faCheckCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Store from "../api/store";

const useStyles = makeStyles((theme) => ({
  root: {},
  checkSelected: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  hideUpToMd: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  hideDownToMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  borderSelected: {
    [theme.breakpoints.down("xs")]: {
      borderWidth: "1px",
      borderColor: theme.palette.primary.main,
      border: "solid",
    },
  },
  wizardIconCart: {
    [theme.breakpoints.down("xs")]: {
      "& .wizardIconCart-icon": {
        display: "none",
      },
    },
    "& .wizardIconCart-icon": {
      fontSize: 90,
      ...theme.spo.icon,
    },
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  subTitle: {
    color: theme.palette.text.secondary,
  },
}));

const WizardCart = (props) => {
  const title = [
    "Seleziona la modalità di consegna",
    "Seleziona il giorno di prenotazione della tua spesa",
  ];

  const codeBackgroundImage = ["WizartCart1", "WizartCart2"];

  const subTitle = [
    // "Ci servono le informazioni per la modalità di ritiro della merce per poter iniziare a fare la tua spesa online",
    // "Ci servono le informazioni per il giorno del ritiro della merce per poter iniziare a fare la tua spesa online",
    "",
    "",
  ];
  const RITIRO = 1;
  const DOMICILIO = 2;

  const classes = useStyles();
  const theme = useTheme();
  const [currentStep, setCurrentStep] = React.useState(0);

  const [tooltipAvailable, setTooltipAvailable] = React.useState(false);
  const [tooltipNotAvailable, setTooltipNotAvailable] = React.useState(false);
  const [tooltipSelected, setTooltipSelected] = React.useState(false);

  const [cartTimeAllocation, setCartTimeAllocation] = React.useState(0);

  const [deliveryMode, setDeliveryMOde] = React.useState(null);
  const [noDeliveryMode, setNoDeliveryMode] = React.useState(false);
  const [addressSelect, setAddressSelect] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [noAddress, setNoAddress] = React.useState(false);

  const [shopsSelect, setShopsSelect] = React.useState(null);
  const [haveCartLimit, setHaveCartLimit] = React.useState(null);
  const [shopInfo, setShopInfo] = React.useState(null);
  const [shop, setShop] = React.useState(null);
  const [noShop, setNoShop] = React.useState(false);

  const [timeAvailable, setTimeAvailable] = React.useState(null);
  const [dateDelivery, setDateDelivery] = React.useState(null);
  const [noDateDelivery, setNoDateDelivery] = React.useState(false);
  const [timeSelected, setTimeSelected] = React.useState(null);
  const [noTimeSelected, setNoTimeSelected] = React.useState(false);
  const [dialogLowTime, setDialogLowTime] = React.useState(null);
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    fetchHaveCartLimit();
    //resume old state
    if (props.AppReducers?.stateSaved?.WizardCart) {
      let oldState = props.AppReducers.stateSaved.WizardCart;
      if (oldState) {
        setDeliveryMOde(oldState.deliveryMode);
        setAddress(oldState.address);
        props.SaveStateApp({});
      }
    }
    //ricarico dallo stato dello store attuale
    if (props.CartReducers && props.CartReducers?.idDeliveryMode) {
      let dateAllocation =
        props.CartReducers?.webSpoTimeAllocation?.dateAllocation;
      setDateDelivery(dateAllocation);
      setTimeSelected({
        id: props.CartReducers?.idTimeAllocation,
      });
      setDeliveryMOde(props.CartReducers?.idDeliveryMode);
      setAddress(props.CartReducers?.idAddress);
      setShop(props.CartReducers?.shopId);
    }
  }, []);

  useEffect(() => {
    if (deliveryMode == null) return;
    fetchPv();
  }, [deliveryMode]);

  useEffect(() => {
    if (address == null) return;
    fetchPv();
  }, [address]);

  useEffect(() => {
    if (dateDelivery != null) fetchTimeAvailable();
  }, [dateDelivery]);

  useEffect(() => {
    if (shopsSelect == null) return;
    //ricarico dallo stato dello store attuale
    if (props.CartReducers) {
      let id = props.CartReducers.shopId;
      let sel = shopsSelect.filter((c) => {
        return c.code == id;
      });
      if (sel.length > 0) setShopInfo(sel[0]);
    }
    if (shopsSelect.length == 1) {
      setShopInfo(shopsSelect[0]);
      setShop(shopsSelect[0].code);
    }
  }, [shopsSelect]);

  useEffect(() => {
    if (shop == null) return;
    props.fetchCfgShopSpo(shop);
  }, [shop]);

  useEffect(() => {
    if (haveCartLimit === null || haveCartLimit === undefined) return;
    if (haveCartLimit === false) handlerClickRitiro();
    //se ci sono cartLimit disponibili richiedo gli indirizzi dell'utente , altrimenti non è possibile
    //utilizzare la consegna a domicilio
    if (haveCartLimit) {
      fetchListAddress();
      setDataReady(true);
    }
  }, [haveCartLimit]);

  useEffect(() => {
    if (haveCartLimit === null || haveCartLimit === undefined) return;
    if (shop == null) return;
    //non c'è la consegna a domicilio e c'è solo un punto vendita , va direttamente alla selezione
    //delle fasce
    if (haveCartLimit === false && shopsSelect?.length === 1) {
      stepArray[currentStep].next();
    }
    //ha caricato gli shop e have cart limit , può renderizzare
    setDataReady(true);
  }, [shop, shopsSelect, haveCartLimit]);

  function backStep() {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  }

  const fetchCartSummary = () => {
    let param = {
      shopId: shop,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/GetCartSummary",
      null,
      param,
      true
    ).then((data) => {
      props.SetInfoCart(data);
      props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/Product");
    });
  };

  const fetchCreateCart = () => {
    let action = "";
    //cartTimeAllocation esiste solo se nello store c'è props.CartReducers
    if (props.CartReducers && props.CartReducers.shopId) {
      //se l'utente ha chiesto di fare il resume devo forzare il recupero valore settato dalla pagina home
      action = "UpdateExistingCart";
    } else {
      action = "CreateNewEmptyCart";
    }

    //altrimenti si sta effettuando un inserimento di un nuovo carrello
    //CreateNewEmptyCart( string shopId, int idAddress, int idDeliveryMode, int idFasciaOraria)
    let param = {
      shopId: shop,
      idAddress: address,
      idDeliveryMode: deliveryMode,
      idTimeAllocation: timeSelected.id,
    };

    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/" + action,
      param,
      null,
      true
    ).then((data) => {
      fetchCartSummary();
    });
  };

  const fetchListAddress = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/User/ListAddressSimpleByUser",
      null,
      null,
      true
    ).then((data) => {
      var res = [];
      for (var prop in data) {
        res.push({ key: prop, value: data[prop] });
      }
      if (res.length == 0) {
        setAddressSelect(null);
        return;
      }
      setAddressSelect(res);
    });
  };

  const fetchHaveCartLimit = () => {
    Store.haveCartLimit().then((data) => {
      setHaveCartLimit(data);
    });
  };

  const fetchPv = () => {
    if (deliveryMode == DOMICILIO && !address) {
      setShopsSelect(null);
      return;
    }
    let param = {
      IdAddress: address,
      IdDeliveryMode: deliveryMode,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Store/ListPuntiVenditaSelect",
      null,
      param,
      true
    ).then((data) => {
      setShopsSelect(data);
    });
  };

  const fetchTimeAvailable = () => {
    let param = {
      date: dateDelivery,
      shopId: shop,
      idDeliveryMode: deliveryMode,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Store/ListTimeAvailable",
      null,
      param,
      true
    ).then((data) => {
      setTimeAvailable(data);
    });
  };

  const handlerClickRitiro = () => {
    if (deliveryMode == RITIRO) return;
    setDeliveryMOde(RITIRO);
    setAddress(null);
    setShop(null);
    setShopInfo(null);
  };

  const handlerClickDomicilio = () => {
    if (deliveryMode == DOMICILIO) return;
    setDeliveryMOde(DOMICILIO);
    setShop(null);
    setShopInfo(null);
  };

  const handleSetTimeCart = (time) => {
    let maxConfirm = time.maxOrderConfirmTime;
    let timeDelivery = props.AppReducers.SpoShopCfg.minTimeForDelivery;

    let dateTimeStart = dateDelivery;

    let timeStart = moment(time.timeStart).toDate();
    dateTimeStart.setHours(
      timeStart.getHours(),
      timeStart.getMinutes(),
      timeStart.getSeconds()
    );
    let now = new Date();
    //fake
    // var now = moment(dateTimeStart)
    //   .add(-(timeDelivery + 5), "m")
    //   .toDate();

    //(MaxOrderConfirmTime < ([TimeStartFascia]-[ MinTimeForDelivery])- Now) == true
    var dateForDelivery = moment(dateTimeStart)
      .add(-timeDelivery, "m")
      .toDate();
    var millisForDelivery = dateForDelivery - now; //millisec
    let minuteForDelivery = millisForDelivery / 1000 / 60;
    if (maxConfirm > minuteForDelivery) {
      //ho meno tempo per confermare il carrello rispetto al maxconfirm avviso l'utente
      setDialogLowTime({ minute: minuteForDelivery, time: time });
      return;
    }

    setTimeSelected(time);
  };

  const stepArray = [
    {
      container: (
        <Box>
          <div>
            <Grid container>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={haveCartLimit ? 6 : 12}
                xl={haveCartLimit ? 6 : 12}
                style={{ marginTop: theme.spo.pMd }}
              >
                <Grid container justify={"center"}>
                  <Grid xs={9}>
                    <Paper
                      className={`${classes.wizardIconCart} ${classes.hover} ${
                        deliveryMode == RITIRO ? classes.borderSelected : ""
                      } ${
                        noDeliveryMode && deliveryMode == null
                          ? "alerts-border"
                          : ""
                      }`}
                      style={{
                        textAlign: "center",
                        padding: "15px",
                        height: "270px",
                        ...(theme.spo.wizardCart.paperDeliveryMode || {}),
                      }}
                      id="modeGetOnShop"
                      onClick={handlerClickRitiro}
                    >
                      <h3 style={theme.spo.wizardCart.titleDeliveryMode}>
                        Ritira nel tuo punto vendita
                      </h3>
                      <h4 style={theme.spo.wizardCart.subTitleDeliveryMode}>
                        Seleziona il tuo negozio
                      </h4>
                      <CustomIcon
                        dbCode={"WizardCartTakeOnPointOfSale"}
                        breakpoints={{ xs: "6" }}
                        center={true}
                        icon={
                          <FontAwesomeIcon
                            className={"wizardIconCart-icon"}
                            icon={faPeopleCarry}
                          />
                        }
                      ></CustomIcon>

                      <Box style={{ textAlign: "left" }}>
                        <FontAwesomeIcon
                          className={
                            deliveryMode == RITIRO ? classes.checkSelected : ""
                          }
                          icon={deliveryMode == RITIRO ? faCheck : null} // faTimes}
                          style={{ fontSize: 45 }}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              {haveCartLimit && (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  style={{ marginTop: theme.spo.pMd }}
                >
                  <Grid container justify={"center"}>
                    <Grid xs={9}>
                      <Paper
                        className={`${classes.wizardIconCart} ${
                          classes.hover
                        }  ${
                          deliveryMode == DOMICILIO
                            ? classes.borderSelected
                            : ""
                        } ${
                          noDeliveryMode && deliveryMode == null
                            ? "alerts-border"
                            : ""
                        }`}
                        style={{
                          textAlign: "center",
                          padding: "15px",
                          //minWidth: "270px",
                          height: "270px",
                          // width: "100%",
                        }}
                        id="getAtHome"
                        onClick={handlerClickDomicilio}
                      >
                        <h3 style={theme.spo.wizardCart.titleDeliveryMode}>
                          Consegna a domicilio
                        </h3>
                        <h4 style={theme.spo.wizardCart.subTitleDeliveryMode}>
                          La merce verrà consegnata all'indirizzo specificato
                        </h4>
                        <CustomIcon
                          dbCode={"WizardCartDelivery"}
                          breakpoints={{ xs: "6" }}
                          center={true}
                          icon={
                            <FontAwesomeIcon
                              icon={faTruck}
                              className={"wizardIconCart-icon"}
                            />
                          }
                        ></CustomIcon>

                        <Grid container style={{ textAlign: "left" }}>
                          <Grid item xs={2}>
                            <FontAwesomeIcon
                              className={
                                deliveryMode == DOMICILIO
                                  ? classes.checkSelected
                                  : ""
                              }
                              icon={deliveryMode == DOMICILIO ? faCheck : null} //faTimes}
                              style={{
                                fontSize: 45,
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            {addressSelect ? (
                              <FormControl
                                className={classes.formControl}
                                style={{ width: "100%" }}
                                disabled={deliveryMode == RITIRO}
                                error={
                                  deliveryMode == DOMICILIO && !addressSelect
                                }
                              >
                                <InputLabel id="address-select-label">
                                  {" "}
                                  Indirizzo
                                </InputLabel>

                                <Select
                                  className={
                                    noAddress && address == null
                                      ? "alerts-border"
                                      : ""
                                  }
                                  labelId="address-select-label"
                                  id="address-select"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                >
                                  {addressSelect &&
                                    addressSelect.map((c) => {
                                      return (
                                        <MenuItem value={c.key}>
                                          {c.value}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                                <FormHelperText></FormHelperText>
                              </FormControl>
                            ) : (
                              <h6>
                                Nessun indirizzo selezionabile, premi sul
                                pulsante + per aggiungerne
                              </h6>
                            )}
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              color="primary"
                              aria-label="add address"
                              id="addAddress"
                              onClick={() => {
                                props.SaveStateApp({
                                  page: "WizardCart",
                                  state: {
                                    address,
                                    shop,
                                    deliveryMode,
                                  },
                                });
                                props.history.push(
                                  process.env.REACT_APP_BASE_PATH_ROUTE +
                                    "/GestioneAccount/WizardCart"
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </Box>
      ),
      container1: (
        <Box style={{ padding: 0 }}>
          {deliveryMode != null && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h3
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    color: theme.palette.primary.main,
                  }}
                >
                  Seleziona il punto vendita
                </h3>
                {deliveryMode == DOMICILIO && (
                  <h4 style={{ marginTop: "5px", marginBottom: "5px" }}>
                    I punti vendita proposti sono scelti in base alla
                    disponibilità e alla distanza dall'indirizzo di consegna,
                    verifica che l'indirizzo specificato sia corretto.
                  </h4>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {shopsSelect && shopsSelect.length > 1 ? (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <FormControl
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel id="shop-select-label">
                          Punto vendita
                        </InputLabel>

                        <Select
                          className={
                            noShop && shop == null ? "alerts-border" : ""
                          }
                          disabled={false}
                          labelId="shop-select-label"
                          id="shop-select"
                          value={shop}
                          onChange={(e) => {
                            setShop(e.target.value);
                            var sel = shopsSelect.filter((c) => {
                              return c.code == e.target.value;
                            });
                            if (sel.length > 0) setShopInfo(sel[0]);
                          }}
                        >
                          {shopsSelect &&
                            shopsSelect.map((c) => {
                              return (
                                <MenuItem value={c.code}>
                                  {c.description}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      {shopsSelect && shopsSelect.length == 0 ? (
                        <h6 style={{ marginTop: "5px", marginBottom: "5px" }}>
                          Non ci sono punti vendita disponibili per l'indirizzo
                          specificato
                        </h6>
                      ) : (
                        <Box>
                          {shopInfo && (
                            <Box>
                              <Box>{shopInfo.description}</Box>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: shopInfo.htmlAddress,
                                }}
                              ></div>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      ),
      next: () => {
        if (deliveryMode == null) {
          setNoDeliveryMode(true);
          NotificationManager.error("Selezionare una modalità di consegna");
          return;
        }
        if (deliveryMode == DOMICILIO && address == null) {
          setNoAddress(true);
          NotificationManager.error("Selezionare un indirizzo per la consegna");
          return;
        }
        if (shop == null) {
          setNoShop(true);
          NotificationManager.error("Selezionare un punto vendita");
          return;
        }
        if (!dateDelivery) {
          setDateDelivery(new Date());
        } else {
          setDateDelivery(new Date(dateDelivery));
        }
        setCurrentStep(currentStep + 1);
      },
    },
    {
      container: (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
              <div
                className={
                  noDateDelivery && dateDelivery == null ? "alerts-border" : ""
                }
              >
                {/* <ThemeProvider theme={materialTheme}> */}
                <ThemeProvider>
                  <DatePicker
                    style={{
                      // width: "100%",
                      // maxWidth: "inherit",
                      ...theme.spo.wizardCart.selectDate,
                    }}
                    format="dd/MM/yyyy"
                    // margin="normal"
                    id="date-picker-dialog"
                    label="Seleziona una data per la tua prenotazione"
                    value={dateDelivery}
                    minDate={new Date()}
                    onChange={(date) => {
                      setDateDelivery(date);
                    }}
                    variant="static"
                    // orientation="landscape"
                    // autoOk
                  />
                </ThemeProvider>
              </div>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      ),
      container1: (
        <Box style={{ padding: 0 }} className={"mb-sm"}>
          <div>
            <h3
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                color: theme.palette.primary.main,
              }}
            >
              Seleziona l'ora di ritiro della merce
            </h3>
          </div>

          <div>
            <Paper
              className={
                noTimeSelected && timeSelected == null ? "alerts-border" : ""
              }
            >
              <Grid container>
                {dateDelivery ? (
                  timeAvailable && timeAvailable.length > 0 ? (
                    timeAvailable.map((c) => {
                      // let date = new Date(Date.parse(c.timeStart));
                      // let dateEnd = new Date(Date.parse(c.timeEnd));
                      let date = moment(c.timeStart);
                      let dateEnd = moment(c.timeEnd);
                      return (
                        <Chip
                          disabled={
                            c.cartDelimited == 0 ||
                            c.cartAllocated >= c.cartDelimited
                          }
                          style={{
                            margin: theme.spo.mSm,
                            ...(c.cartDelimited == 0 ||
                            c.cartAllocated >= c.cartDelimited
                              ? theme.spo.wizardCart.chipUnavailable
                              : timeSelected && c.id == timeSelected.id
                              ? theme.spo.wizardCart.chipSelected
                              : theme.spo.wizardCart.chipUnselected),
                          }}
                          icon={<FontAwesomeIcon icon={faClock} />}
                          label={
                            <div>
                              <ReactMoment
                                parse="YYYY-MM-DD HH:mm"
                                format="HH:mm"
                              >
                                {date}
                              </ReactMoment>
                              -
                              <ReactMoment
                                parse="YYYY-MM-DD HH:mm"
                                format="HH:mm"
                              >
                                {dateEnd}
                              </ReactMoment>
                            </div>
                          }
                          deleteIcon={
                            timeSelected && c.id == timeSelected.id ? (
                              <FontAwesomeIcon icon={faCheckCircle} />
                            ) : (
                              <FontAwesomeIcon icon={faCircle} />
                            )
                          }
                          onClick={() => {
                            handleSetTimeCart(c);
                          }}
                          onDelete={() => {
                            handleSetTimeCart(c);
                          }}
                          variant={
                            c.CartAllocated >= c.CartDelimited
                              ? "outlined"
                              : "default"
                          }
                        />
                      );
                    })
                  ) : (
                    <h5 className={"p-sm"}>
                      Non ci sono disponibilità per questo giorno
                    </h5>
                  )
                ) : (
                  <h5 className={"p-sm"}>
                    Per verificare le disponibilità devi prima selezionare una
                    data
                  </h5>
                )}
              </Grid>
              <hr></hr>
              <Grid container>
                <Grid item xs={4}>
                  <Tooltip
                    open={tooltipAvailable}
                    onClose={() => {
                      setTooltipAvailable(false);
                    }}
                    onOpen={() => {
                      setTooltipAvailable(true);
                    }}
                    title="Orario disponibile"
                  >
                    <Chip
                      className={"m-sm"}
                      variant={"default"}
                      label={"00:00"}
                    ></Chip>
                  </Tooltip>
                  <span className={classes.hideDownToMd}>
                    Orario disponibile
                  </span>
                  <IconButton
                    className={classes.hideUpToMd}
                    style={{ padding: 0 }}
                    onTouchStartCapture={() => {
                      setTooltipAvailable(true);
                    }}
                    onMouseDown={() => {
                      setTooltipAvailable(true);
                    }}
                    onMouseUp={() => {
                      setTooltipAvailable(false);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: "14px" }}
                      icon={faInfoCircle}
                    />
                  </IconButton>
                </Grid>

                <Grid item xs={4}>
                  <Tooltip
                    open={tooltipSelected}
                    onClose={() => {
                      setTooltipSelected(false);
                    }}
                    onOpen={() => {
                      setTooltipSelected(true);
                    }}
                    title="Orario selezionato"
                  >
                    <Chip
                      style={theme.spo.wizardCart.chipSelected}
                      className={"m-sm"}
                      variant={"default"}
                      label={"00:00"}
                    ></Chip>
                  </Tooltip>
                  <span className={classes.hideDownToMd}>
                    Orario selezionato
                  </span>
                  <IconButton
                    className={classes.hideUpToMd}
                    style={{ padding: 0 }}
                    onTouchStartCapture={() => {
                      setTooltipSelected(true);
                    }}
                    onMouseDown={() => {
                      setTooltipSelected(true);
                    }}
                    onMouseUp={() => {
                      setTooltipSelected(false);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: "14px" }}
                      icon={faInfoCircle}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip
                    open={tooltipNotAvailable}
                    onClose={() => {
                      setTooltipNotAvailable(false);
                    }}
                    onOpen={() => {
                      setTooltipNotAvailable(true);
                    }}
                    title="Orario non disponibile"
                  >
                    <Chip
                      disabled={true}
                      style={theme.spo.wizardCart.chipUnavailable}
                      className={"m-sm"}
                      // variant={"outlined"}
                      label={"00:00"}
                    ></Chip>
                  </Tooltip>
                  <span className={classes.hideDownToMd}>
                    Orario non disponibile
                  </span>
                  <IconButton
                    className={classes.hideUpToMd}
                    style={{ padding: 0 }}
                    onTouchStartCapture={() => {
                      setTooltipNotAvailable(true);
                    }}
                    onMouseDown={() => {
                      setTooltipNotAvailable(true);
                    }}
                    onMouseUp={() => {
                      setTooltipNotAvailable(false);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: "14px" }}
                      icon={faInfoCircle}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogWithContent
                open={dialogLowTime}
                onClose={() => {
                  setDialogLowTime(null);
                }}
                title={"Attenzione"}
                contentText={
                  <span>
                    {dialogLowTime && (
                      <>
                        Per rispettare gli orari di consegna richiesti, sarà
                        necessario confermare il carrello entro le
                        {moment(new Date())
                          .add(dialogLowTime.minute, "m")
                          .format("HH:mm")}
                      </>
                    )}
                  </span>
                }
                action={[
                  {
                    onClick: () => {
                      setDialogLowTime(null);
                    },
                    label: "Annulla",
                  },
                  {
                    onClick: () => {
                      setTimeSelected(dialogLowTime.time);
                      setDialogLowTime(null);
                    },
                    label: "Si, voglio usare questo orario",
                  },
                ]}
              ></DialogWithContent>
            </Paper>
          </div>
        </Box>
      ),
      next: () => {
        if (dateDelivery == null) {
          setNoDateDelivery(true);
          NotificationManager.error("Nessuna data selezionata");
          return;
        }
        if (timeSelected == null) {
          setNoTimeSelected(true);
          NotificationManager.error(
            "Devi selezionare una fascia valida di ritiro/consegna "
          );
          return;
        }
        //time selected potrebbe esser solo un id quindi lo prendo dalla lista
        var time = timeAvailable.filter((c) => c.id == timeSelected.id);
        setTimeSelected(time);
        if (time[0].cartDelimited == 0) {
          setNoTimeSelected(true);
          NotificationManager.error(
            "Devi selezionare una fascia oraria disponibile, se non ne trovi per questa data prova a sceglierne un altra"
          );
          return;
        }

        fetchCreateCart();
      },
    },
  ];

  return (
    <div>
      <SpoLayoutBg
        titleHeadText={title[currentStep]}
        codeImage={codeBackgroundImage[currentStep]}
        mainContainer={
          <div className={classes.root}>
            {dataReady && <div>{stepArray[currentStep].container}</div>}
          </div>
        }
        marginSeparator={"10px"}
        secondaryContainer={
          <div>
            {dataReady && (
              <>
                <div>{stepArray[currentStep].container1}</div>
                {dateDelivery && timeSelected && timeSelected.timeStart && (
                  <div
                    className={"mb-sm"}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Prenotazione per il{" "}
                    <ReactMoment format="DD-MM-YYYY">
                      {dateDelivery}
                    </ReactMoment>{" "}
                    alle{" "}
                    <ReactMoment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                      {timeSelected.timeStart}
                    </ReactMoment>
                    -
                    <ReactMoment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                      {timeSelected.timeEnd}
                    </ReactMoment>
                  </div>
                )}
                <Grid container spacing={3} justify="center">
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {currentStep > 0 && (
                      <Button
                        style={{ marginRight: "10px" }}
                        color="primary"
                        variant="contained"
                        onClick={backStep}
                      >
                        Indietro
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={stepArray[currentStep].next}
                    >
                      Continua
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </div>
        }
        // bottomTitle={"Spesa online"}
        bottomTitle={""}
        bottomSubtitle={subTitle[currentStep]}
      ></SpoLayoutBg>
      <Footer></Footer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {
  SaveStateApp,
  SetInfoCart,
  fetchCfgShopSpo,
})(WizardCart);
