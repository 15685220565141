import { GET } from "request-helper";

export const Store = {
  haveCartLimit: () => {
    return GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Store/HaveCartLimit",
      null,
      null,
      true
    );
  },
};

export default Store;
