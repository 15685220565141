import React, { useState } from "react";
import { connect } from "react-redux";
import DialogWithContent from "./DialogWithContent";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CookieConsent from "react-cookie-consent";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.spo.footer,
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [privacyDialog, setPrivacyDialog] = useState(false);
  const [sellerDialog, setSellerDialog] = useState(false);
  const [cookieDialog, setCookieDialog] = useState(false);
  const [howToDialog, setHowToDialog] = useState(false);

  return (
    <div className={classes.root}>
      <Grid container className={"footer-up"}>
        <Grid item xs={1} sm={1} md={2}></Grid>
        <Grid item xs={12} sm={5} md={4} style={{ textAlign: "left" }}>
          <h3>INFORMAZIONI</h3>
          <Box>
            <Link
              href="#"
              onClick={() => {
                setPrivacyDialog(true);
              }}
            >
              Informativa sulla Privacy
            </Link>
          </Box>
          <Box>
            <Link
              href="#"
              onClick={() => {
                setSellerDialog(true);
              }}
            >
              Condizioni di vendita
            </Link>
          </Box>
          <Box>
            <Link
              href="#"
              onClick={() => {
                setCookieDialog(true);
              }}
            >
              Informativa sui cookie
            </Link>
          </Box>
          <Box
            style={{
              marginTop: theme.spo.pXs,
              color: theme.palette.primary.main,
            }}
          >
            <FontAwesomeIcon
              icon={["fab", "cc-visa"]}
              style={{
                fontSize: 36,
                paddingRight: theme.spo.pXs,
              }}
            />
            <FontAwesomeIcon
              icon={["fab", "cc-mastercard"]}
              style={{
                fontSize: 36,
                paddingRight: theme.spo.pXs,
              }}
            />
            <FontAwesomeIcon
              icon={["fab", "cc-amex"]}
              style={{
                fontSize: 36,
                paddingRight: theme.spo.pXs,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={4} style={{ textAlign: "right" }}>
          <h3>ASSISTENZA</h3>
          <Box className="">
            <Link
              href="#"
              onClick={() => {
                setHowToDialog(true);
              }}
            >
              Come funziona ShopOnline
            </Link>
          </Box>
          <Box className="">
            <Link
              href={
                "mailto:" +
                props.store?.AppReducers?.SpoCfg?.emailCustomerService
              }
            >
              Contattaci
            </Link>
          </Box>
        </Grid>
        <Grid item xs={1} sm={1} md={2}></Grid>
      </Grid>
      <Grid container className={"footer-down"}>
        <Grid
          item
          xs={12}
          style={{
            minHeight: "30px",
            textAlign: "center",
            ...theme.spo?.footerCompanyData,
          }}
        >
          <div style={{ padding: theme.spo.pXs }}>
            {props.store?.AppReducers?.SpoCfg?.companyData}
          </div>
        </Grid>
      </Grid>
      <DialogWithContent
        key={"dl-privacy"}
        open={privacyDialog}
        onClose={() => {
          setPrivacyDialog(false);
        }}
        contentText={
          <div
            dangerouslySetInnerHTML={{
              __html: props.store?.AppReducers?.SpoCfg?.privacyConditions,
            }}
          ></div>
        }
        title="Informativa Privacy"
        action={[
          {
            onClick: () => {
              setPrivacyDialog(false);
            },
            label: "Chiudi",
          },
        ]}
      ></DialogWithContent>

      <DialogWithContent
        key={"dl-seller"}
        open={sellerDialog}
        onClose={() => {
          setSellerDialog(false);
        }}
        contentText={
          <div
            dangerouslySetInnerHTML={{
              __html: props.store?.AppReducers?.SpoCfg?.sellingConditions,
            }}
          ></div>
        }
        title="Condizioni di vendita"
        action={[
          {
            onClick: () => {
              setSellerDialog(false);
            },
            label: "Chiudi",
          },
        ]}
      ></DialogWithContent>

      <DialogWithContent
        key={"dl-cookie"}
        open={cookieDialog}
        onClose={() => {
          setCookieDialog(false);
        }}
        contentText={
          <div
            dangerouslySetInnerHTML={{
              __html: props.store?.AppReducers?.SpoCfg?.cookiesConditions,
            }}
          ></div>
        }
        title="Informativa sui cookie"
        action={[
          {
            onClick: () => {
              setCookieDialog(false);
            },
            label: "Chiudi",
          },
        ]}
      ></DialogWithContent>

      <DialogWithContent
        key={"dl-how-to"}
        open={howToDialog}
        onClose={() => {
          setHowToDialog(false);
        }}
        contentText={
          <div>
            <h1>Come fare la spesa online con ShopOnline</h1>
            <p>
              Ti piacerebbe fare la spesa comodamente da casa, acquistando i
              tuoi prodotti preferiti ma evitando così le lunghe code al
              supermercato? Grazie a ShopOnline, tutto questo è possibile. Con
              il nuovo servizio di spesa web puoi ritrovare la stessa qualità e
              fiducia di sempre.
            </p>
            <p>
              Fare la spesa su internet è semplice, proprio come farla presso un
              qualsiasi punto vendita fisico. Il primo step è entrare nel
              portale di ShopOnline e scegliere le modalità di consegna: ritiro
              in negozio o consegna a casa. In base alla tipologia scelta il
              funzionamento e gli eventuali costi saranno leggermente diversi.
            </p>
            <h2>Come funziona la spesa online a domicilio</h2>
            <p>
              Il procedimento della spesa a domicilio è veramente semplice e
              intuitivo. Tutto ciò che devi fare è seguire questi semplici step:
            </p>
            <ul>
              <li> Registrarti o accedere con il tuo account registrato</li>
              <li> Indicare l’indirizzo di consegna della spesa</li>
              <li>
                {" "}
                Selezionare il tuo negozio di fiducia tra quelli che ti verranno
                proposti
              </li>
              <li>
                {" "}
                Prenotare il giorno e la fascia oraria per la consegna a casa
              </li>
              <li>
                {" "}
                Scorrere il catalogo e scegliere i prodotti dai vari reparti
              </li>
              <li> Aggiungerli al carrello</li>
              <li> Ricontrollare la lista della spesa</li>
              <li> Andare alla cassa </li>
              <li>
                {" "}
                Scegliere la modalità di pagamento preferita per concludere
                l’ordine.
              </li>
              <li>
                {" "}
                Verrai informato via mail degli stati di avanzamento del tuo
                ordine.
              </li>
            </ul>
            <h2>Come funziona la spesa online con ritiro in negozio</h2>
            <p>
              Se hai scelto la spesa con ritiro in negozio, il procedimento è
              pressoché identico al precedente:
            </p>
            <ul>
              <li>Registrarti o accedere con il tuo account registrato</li>
              <li>
                {" "}
                Seleziona il negozio di fiducia presso il quale vuoi acquistare
                la spesa
              </li>
              <li>
                {" "}
                Prenota il giorno e la fascia oraria in base alla disponibilità
              </li>
              <li>
                {" "}
                Scorri il catalogo e scegliere i prodotti dai vari reparti,
                aggiungendoli al carrello
              </li>
              <li> Ricontrolla la lista della spesa</li>
              <li> Vai alla cassa </li>
              <li> Seleziona la modalità di pagamento e concludi l’ordine.</li>
              <li>
                {" "}
                Verrai informato via mail degli stati di avanzamento del tuo
                ordine.
              </li>
            </ul>
            <p>
              Ora puoi goderti tutto il tempo risparmiato, con la consapevolezza
              che qualcuno di cui ti fidi sta lavorando per garantiti
              l’eccellenza di sempre.
            </p>
          </div>
        }
        // title="COME FUNZIONA SPESA ONLINE"
        title=""
        action={[
          {
            onClick: () => {
              setHowToDialog(false);
            },
            label: "Chiudi",
          },
        ]}
      ></DialogWithContent>
      <CookieConsent
        location="bottom"
        buttonText="Accetta"
        cookieName="accettazioneCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
        Questo sito utilizza cookie tecnici e cookie di profilazione, al fine di
        rendere più rapido e migliore il suo utilizzo. Se vuoi saperne di più o
        modificare le impostazioni del tuo browser relativamente ai cookies,
        fino ad eventualmente escluderne l'installazione, visualizza l'{" "}
        <Link
          href="#"
          onClick={() => {
            setCookieDialog(true);
          }}
        >
          l'INFORMATIVA
        </Link>
        . Proseguendo la navigazione acconsenti all'uso dei cookie
      </CookieConsent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};

export default connect(mapStateToProps, {})(Footer);
