export default {
  theme: {
    palette: {
      primary: {
        main: "#5e2a2b",
      },
    },
    overrides: {
      palette: {
        primary: {
          main: "#5e2a2b",
        },
      },
      MuiPaper: {
        root: { backgroundColor: "#faf4e8" },
      },
      MuiTypography: {
        root: {
          color: "#5e2a2b",
        },
        colorPrimary: {
          color: "#5e2a2b",
        },
      },
      MuiRadio: {
        colorSecondary: {
          "&.Mui-checked": { color: "#5e2a2b" },
          color: "#5e2a2b",
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          "&.Mui-checked": { color: "#5e2a2b" },
          color: "#5e2a2b",
        },
      },
      MuiButton: {
        root: { color: "#5e2a2b" },
        containedPrimary: {
          "&:hover": {
            color: "#f1dab1",
            backgroundColor: "#5e2a2b",
          },
          color: "#f1dab1",
          backgroundColor: "#5e2a2b",
        },
      },
      MuiIconButton: {
        root: {
          borderRadius: 0,
          color: "#5e2a2b",
        },
        colorPrimary: {
          color: "#5e2a2b",
        },
        label: {
          justifyContent: "flex-start",
          "& p": {
            marginLeft: "10px",
            marginTop: "20px",
          },
        },
      },

      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: "inherit",
          width: "100%",
        },
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          backgroundColor: "transparent",
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#5e2a2b",
          color: "#f1dab1",
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: { color: "#f1dab1" },
        toolbarBtnSelected: { color: "#f1dab1" },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          backgroundColor: "#5e2a2b",
          color: "#f1dab1",
        },
        iconButton: {
          backgroundColor: "#5e2a2b",
          color: "#f1dab1",
          "&:hover": {
            backgroundColor: "#2f3f96",
            color: "#f1dab1",
          },
        },
        dayLabel: {
          margin: "5px 5px",
          color: "#000",
          width: "100%",
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          height: "300px",
        },
        week: {
          "& div[role=presentation]": {
            width: "100%",
          },
        },
      },

      MuiPickersDay: {
        day: {
          color: "#000",
          backgroundColor: "#ffffffbd",
          height: "40px",
          margin: "5px 5px",
          width: "95%",
          "&:hover": {
            backgroundColor: "#5e2a2b",
            color: "#f1dab1",
          },
        },
        daySelected: {
          backgroundColor: "#5e2a2b",
          color: "#f1dab1",
          "&:hover": {
            backgroundColor: "#5e2a2b",
            color: "#f1dab1",
          },
        },
        dayDisabled: {
          color: "#ccc",
        },
        current: {
          color: "#3f51b5",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#f1dab1",
        },
      },
      PrivateTabIndicator: {
        colorSecondary: { backgroundColor: "#5e2a2b" },
      },
    },
    spo: {
      mXs: 5,
      mSm: 15,
      mMd: 30,
      mLg: 40,
      pXs: 5,
      pSm: 15,
      pMd: 30,
      pLg: 40,
      darkGray: {
        color: "#6e6d6e",
      },
      toolBar: {
        backgroundColor: "#fff",
        // "height": "25px",
        minHeight: "25px",
        maxHeight: "40px",
        color: "#6e6d6e",
        borderBottom: "solid 1px #ccc",
        boxShadow: "1px 1px 13px 6px #ccc",
      },
      icon: { color: "#5e2a2b" },
      secondaryBar: {
        backgroundColor: "#5e2a2b",
      },
      mainHomePaperRight: {
        backgroundColor: "rgba(241, 218, 177,  0.8)",
      },
      mainHomeMainTitle: {
        color: "#5e2a2b",
        textShadow: "none",
        fontWeight: "bolder",
        fontSize: "36px",
      },
      titleServizioSpesa: {
        fontWeight: 300,
        fontSize: 24,
        textAlign: "center",
      },
      footer: {
        "& a": {
          color: "#5e2a2b",
        },
        "& svg": {
          color: "#5e2a2b",
        },
        "& .footer-up": { padding: "30px" },
        "& .footer-down": {},
        width: "100%",
        // marginTop: "50px",

        minHeight: "200px",
        backgroundColor: "#f1dab1",
        color: "#5e2a2b",
      },
      footerCompanyData: {
        backgroundColor: "#5e2a2b",
        color: "#f1dab1",
      },
      imageView: {
        containerImage: {
          backgroundColor: "#fff",
          borderRadius: "10px",
          margin: "0 15px",
          padding: "15px",
          marginBottom: "15px",
          height: "300px",
          textAlign: "center",
        },
      },
      spoLayoutBg: {
        logoContainer: {
          backgroundColor: "#none",
          position: "absolute",
          right: 0,
          padding: "15px",
          marginTop: "-25px",
          zIndex: "1",
        },
        paperWithOpacity: {
          backgroundColor: "rgba(241, 218, 177,  0.8)",
        },
      },
      freeHome: {
        logo: {
          paddingTop: "80px",
        },
        subTitle: {
          fontSize: "20px",
          marginTop: "40px",
          textAlign: "center",
          color: "#5e2a2b",
        },
      },
      wizardCart: {
        chipSelected: { backgroundColor: "#4caf50" },
        paperDeliveryMode: {
          backgroundColor: "#faf4e8",
        },
        titleDeliveryMode: {
          marginTop: "20px",
          marginBottom: "10px",
          fontWeight: 500,
          fontSize: "32px",
          letterSpacing: "-1px",
          color: "#5e2a2b",
        },
        subTitleDeliveryMode: {
          marginTop: 0,
          fontWeight: "400",
          color: "#5e2a2b",
        },
        chipUnselected: {},
        chipUnavailable: { backgroundColor: "#ff0000", color: "#d6c8c8" },
        selectDate: {},
      },
      product: {
        mainHeader: {
          backgroundColor: "#f1dab1",
        },
        textBoxSearch: {
          backgroundColor: "#fff",
          borderRadius: "50px",
          marginBottom: "5px",
        },
        headerListProduct: {
          backgroundColor: "#fff",
          borderTopRightRadius: "25px",
          borderTopLeftRadius: "25px",
        },
        deliverySummary: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: "12px",
          display: "inline",
          color: "#5e2a2b",
        },

        IconHome: {},
        IconDeparment: {
          fontSize: "24px",
          color: "#6e6d6e",
        },

        IconDeparmentSelected: {
          fontSize: "24px",
          color: "#ffffff",
        },
        iconHeadTrash: {
          color: "#5e2a2b",
        },
        iconHeadCart: { color: "#fff" },
        colorFirstRowHead: "#6e6d6e",
        backgroundPaper: "#ffffff",
        backgroundPage: "#faf4e8",
        breadcrumbBar: {
          padding: "5px",
          backgroundColor: "#5e2a2b",
          borderRadius: "5px",
          color: "#fff",
          marginTop: "5px",
          marginBottom: "10px",
        },
        sizeIconDisplayProduct: "28px",
        departmentSelected: {
          width: "7%",
          height: "72px",
          fontSize: "10px",
          backgroundColor: "#5e2a2b",
          borderColor: "#0000ff",
          fontWeight: "bold",
          color: "#ffffff",
          border: "none",
        },
        departmentUnSelected: {
          width: "7%",
          height: "72px",
          fontSize: "10px",
          border: "none",
        },
        card: {
          paperBackgroundColor: "#fff",
          sizeCategoryText: 10,
          sizeDescriptionText: 16,
          sizeBrandText: 12,
          sizePriceText: "20px",
          backGround: "#fff",
          colorCategory: "#000000",
          colorDescription: "#4d4d4d",
          colorBrand: "#000000",
          colorPrice: "#4d4d4d",
        },
        departmentPopLeft: {
          backgroundColor: "#5e2a2b",
          textAlign: "center",
          color: "#f1dab1",
        },
        textExpirationCart: {
          fontSize: "12px",
          color: "#5e2a2b",
        },
      },
    },
  },
};
