/**************************************************************
 *                  import external package
 /************************************************************/

//https://itnext.io/add-confirmation-dialog-to-react-events-f50a40d9a30d
import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DialogConfirm = (props) => {
  const props_stateWrapOpen = props.stateWrapOpen;
  // //bool
  // const props_open = props.open;
  // //function
  // const props_onClose = props.onClose;
  const props_title = props.title;
  const props_contentText = props.text;
  const props_onlyOkText = props.onlyOkText;

  const [state, setState] = React.useState({
    open: false,
    callback: () => null,
  });

  const show = (callback) => (event) => {
    event.preventDefault();

    event = {
      ...event,
      target: { ...event.target, value: event.target.value },
    };

    setState({
      open: true,
      callback: () => callback(event),
    });
  };

  const hide = () => setState({ open: false, callback: null });

  const confirm = () => {
    state.callback();
    hide();
  };

  return (
    <span>
      {props.children(show)}
      <Dialog
        open={state.open}
        onClose={() => {
          hide();
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{props_title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props_contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {!props_onlyOkText && (
            <Button
              onClick={() => {
                hide();
              }}
              color="primary"
            >
              Annulla
            </Button>
          )}
          <Button
            onClick={() => {
              confirm();
            }}
            color="primary"
          >
            {props_onlyOkText || "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default DialogConfirm;
