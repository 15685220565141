import { GET, POST } from "request-helper";

import { connect } from "react-redux";
import { useIntl } from "react-intl";

import InvoiceData from "../components/Invoice/InvoiceData";
import validationInvoice from "./WizardUserValidation";

import SpoLayoutBg from "../components/SpoLayoutBg";
import Footer from "../components/Footer";
import {
  geocodeAddress,
  parseAddressComponents,
} from "../components/geocodeAddress";

import { validateNumber } from "../utility/utility";

import PrivacyConditions from "../components/Conditions/PrivacyConditions";

import React, { useCallback, useEffect } from "react";
import {
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";

import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Store from "../api/store";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
  },
  media: {
    height: 140,
  },
  title: {
    color: theme.palette.primary.main,
  },
  subTitle: {
    color: theme.palette.text.secondary,
  },
  hideUnderSmall: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

var timeoutSearchAddres = -1;

const WizardUser = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [currentStep, setCurrentStep] = React.useState(0);

  const [privacy, setPrivacy] = React.useState(false);
  const [privacyUnchecked, setPrivacyUnchecked] = React.useState(false);

  const [promoUnchecked, setPromoUnchecked] = React.useState(null);
  const [profilerUnchecked, setProfilerUnchecked] = React.useState(null);

  const [haveTessera, setHaveTessera] = React.useState(false);
  const [codeTessera, setCodeTessera] = React.useState("");
  const [msgTessera, setMsgTessera] = React.useState("");

  const [allCountry, setAllCountry] = React.useState([]);

  const [userData, setUserData] = React.useState({
    name: "",
    surname: "",
    email: "",
    mobile: "",
    invoiceBusinessName: "",
    invoiceAddress: "",
    dateBirth: null,
    privacyPromo: "false",
  });
  const [msgCell, setMsgCell] = React.useState("");

  const [haveInvoice, setHaveInvoice] = React.useState(false);

  const [haveAddress, setHaveAddress] = React.useState(false);
  const [address, setAddress] = React.useState({
    address: "",
    city: "",
    postalCode: "",
    province: "",
  });
  const [geocodedAddress, setGeocodedAddress] = React.useState(null);
  const [flagConfirmAddress, setFlagConfirmAddress] = React.useState(false);

  const [haveCartLimit, setHaveCartLimit] = React.useState(null);

  useEffect(() => {
    // if (
    //   address.address &&
    //   address.city &&
    //   address.postalCode &&
    //   address.province
    // )
    //   timeoutFetchAddress();
  }, [address]);

  useEffect(() => {
    fetchAllcountry();
    fetchHaveCartLimit();
  }, []);

  var fetchHaveCartLimit = useCallback(() => {
    Store.haveCartLimit().then((data) => {
      setHaveCartLimit(data);
    });
  });

  function backStep() {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  }

  const fetchAddUser = () => {
    // var model = {
    //   Name: userData.name,
    //   Surname: userData.surname,
    //   Mobile: userData.mobile,
    //   FidelityCardCode: userData.id,
    // };
    var model = { ...userData };

    if (haveAddress) {
      let _address = parseAddress("route", false);
      let _streetNumber = parseAddress("street_number", false);
      if (_streetNumber) _address = _address + ", " + _streetNumber;
      let _locality = parseAddress("administrative_area_level_3", false);
      let _prov = parseAddress("administrative_area_level_2", true);
      let _postalCode = parseAddress("postal_code", false);

      model.WebSpoAddress = [
        {
          Address: _address || address.address,
          City: _locality || address.city,
          PostalCode: _postalCode || address.postalCode,
          Province: _prov || address.province,
          Lat: geocodedAddress[0]?.geometry?.location?.lat,
          Lon: geocodedAddress[0]?.geometry?.location?.lng,
        },
      ];
    }

    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/User/AddUser",
      model,
      null,
      true
    ).then(() => {
      props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
    });
  };

  const fetchTessera = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Store/GetUserInfoFromFidelity",
      null,
      { fidelityCardCode: codeTessera },
      true
    ).then((data) => {
      if (data.Status == 500) {
        if (
          data.ExceptionDetail &&
          data.ExceptionDetail.Code == "AlreadyUsed"
        ) {
          setMsgTessera("Tessera associata ad un altro utente");
          return;
        }
      }
      if (!data.id) {
        setMsgTessera("Codice tessera non valido");
        return;
      }
      var { id, ...rest } = data;
      rest.fidelityCardCode = id;
      setUserData({ ...userData, ...rest });
      setCurrentStep(currentStep + 1);
    });
  };

  const timeoutFetchAddress = () => {
    clearTimeout(timeoutSearchAddres);
    timeoutSearchAddres = setTimeout(() => {
      fetchGeocodeAddress();
    }, 2000);
  };

  const parseAddress = (type, short) => {
    let addressComponents = geocodedAddress[0].address_components;
    return parseAddressComponents(addressComponents, type, short);
  };

  const setMsgCellRequired = () => {
    setMsgCell("Hai richiesto la consegna a domicilio , cellulare obblgatorio");
  };

  const fetchGeocodeAddress = () => {
    let fullAddress =
      address.address +
      " " +
      address.city +
      " " +
      address.postalCode +
      " " +
      address.province;

    geocodeAddress(fullAddress, (data) => {
      setGeocodedAddress(data);
    });
  };

  const fetchAllcountry = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/User/AllCountry",
      null,
      null,
      true
    ).then((data) => {
      setAllCountry(data);
    });
  };

  const stepArray = [
    //fidelity card
    {
      container: (
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} lg={8}>
            <Grid item xs={12}>
              <Box p={3}>
                <FormControlLabel
                  checked={haveTessera}
                  control={<Checkbox color="primary" />}
                  label="Hai già una fidelity card?"
                  labelPlacement="end"
                  onChange={(e) => {
                    setHaveTessera(e.target.checked);
                  }}
                />
                <div>
                  Se hai già registrata una fidelity card utilizzermo i dati per
                  effettuare la registrazione al servizio spesa online
                </div>
              </Box>
              <Box p={3}>
                <TextField
                  error={msgTessera}
                  helperText={msgTessera ? msgTessera : ""}
                  disabled={!haveTessera}
                  value={codeTessera}
                  style={{ width: "100%" }}
                  id="textfield-fidelity"
                  label="Numero tessera"
                  onChange={(e) => {
                    setCodeTessera(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              textAlign: "center",
              marginTop: "100px",
            }}
            className={classes.hideUnderSmall}
          >
            <FontAwesomeIcon
              icon={faIdCard}
              style={{ fontSize: 180, ...theme.spo.icon }}
            />
          </Grid>
        </Grid>
      ),
      next: () => {
        if (haveTessera) {
          fetchTessera();
          return;
        }
        setCurrentStep(currentStep + 1);
      },
    },
    //user data
    {
      container: (
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            <TextField
              //   disabled={haveTessera}
              required={true}
              error={!userData.name}
              style={{ width: "100%" }}
              label="Nome"
              value={userData.name}
              onChange={(e) => {
                setUserData({ ...userData, ...{ name: e.target.value } });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              //   disabled={haveTessera}
              required={true}
              error={!userData.surname}
              style={{ width: "100%" }}
              label="Cognome"
              value={userData.surname}
              onChange={(e) => {
                setUserData({ ...userData, ...{ surname: e.target.value } });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                // required={false}
                // error={!userData.dateBirth}
                style={{ width: "100%" }}
                label="Data di nascita"
                value={userData.dateBirth}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    ...{ dateBirth: e },
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Sesso</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={userData.gender}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    ...{ gender: e.target.value },
                  });
                }}
              >
                <FormControlLabel
                  value="f"
                  control={<Radio />}
                  label="Femmina"
                />
                <FormControlLabel
                  value="m"
                  control={<Radio />}
                  label="Maschio"
                />
                <FormControlLabel
                  value="n"
                  control={<Radio />}
                  label="Non specificato"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* <Grid item xs={6}>
            <TextField
            //   disabled={haveTessera}
              style={{ width: "100%" }}
              id="standard-required"
              label="Email"
              value={userData.email}
              onChange={(e) => {
                setUserData({ ...userData, ...{ email: e.target.value } });
              }}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              //   disabled={haveTessera}
              error={msgCell}
              style={{ width: "100%" }}
              label="Cellulare"
              value={userData.mobile}
              onChange={(e) => {
                if (validateNumber(e.target.value)) {
                  setUserData({ ...userData, ...{ mobile: e.target.value } });
                }
              }}
              helperText={
                msgCell ||
                "Numero di cellulare valido obbligatorio in caso di consegna a domicilio"
              }
            />
          </Grid>
        </Grid>
      ),
      next: () => {
        if (userData.name && userData.surname) {
          setMsgCell("");
          setCurrentStep(currentStep + 1);
        } else {
          NotificationManager.error(
            "Impossibile proseguire , dati obbligatori mancanti"
          );
        }
      },
    },
    //billing data
    {
      container: (
        <Grid
          container
          spacing={3}
          justify="center"
          // style={{ height: "400px" }}
        >
          <Grid item xs={12}>
            <Box>
              <FormControlLabel
                id="haveInvoice"
                checked={haveInvoice}
                control={<Checkbox color="primary" />}
                label="Vuoi inserire i dati di fatturazione?"
                labelPlacement="end"
                onChange={(event) => {
                  setHaveInvoice(event.target.checked);
                }}
              />
              <div>
                Potrai inserire o modificare questa opzione successivamente
                andando nella tua area account
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InvoiceData
              haveInvoice={haveInvoice}
              setHaveInvoice={setHaveInvoice}
              userData={userData}
              setUserData={setUserData}
              // setNotSaved={setNotSAved}
              allCountry={allCountry}
              showSwitch={false}
            ></InvoiceData>
          </Grid>
        </Grid>
      ),
      next: () => {
        if (haveInvoice) {
          var vInvoice = validationInvoice(haveInvoice, userData);
          for (var valid in vInvoice) {
            if (vInvoice[valid].required == true && !userData[valid]) {
              NotificationManager.error(
                "Devi inserire tutti i dati obbligatori prima di proseguire"
              );
              return;
            }
          }
          for (var valid in vInvoice) {
            var val =
              typeof vInvoice[valid].error == "function"
                ? vInvoice[valid].error()
                : vInvoice[valid].error;
            if (val) {
              NotificationManager.error(
                "Dati non validi , prima di proseguire verificarne la correttezza"
              );
              return;
            }
          }
        }

        setCurrentStep(currentStep + 1);
        return;
      },
    },
    //delivery data
    {
      container: (
        <Grid
          container
          spacing={3}
          justify="center"
          // style={{ height: "400px" }}
        >
          {haveCartLimit && (
            <>
              <Grid item xs={12}>
                <Box>
                  <FormControlLabel
                    checked={haveAddress}
                    control={<Checkbox color="primary" />}
                    label="Desideri usufruire del servizio di consegna a domicilio?"
                    labelPlacement="end"
                    onChange={(event) => {
                      setHaveAddress(event.target.checked);
                      if (event.target.checked == true && !userData.mobile) {
                        NotificationManager.warning(
                          "Attenzione , per la consegna a domicilio è obbligatorio il numero di cellulare"
                        );
                      }
                    }}
                  />
                  <div>
                    Potrai inserire o modificare questa opzione successivamente
                    andando nella tua area account
                  </div>
                  {/* <div>
                {geocodedAddress && geocodedAddress[0] && (
                  <Paper style={{ marginTop: "5px" }}>
                    <div style={{ marginLeft: "5px" }}>
                      <div style={{ paddingTop: "5px" }}>
                        <b>Indirizzo trovato</b>
                        <div>
                          Verranno salvati nel sistema i seguenti dati ,
                          verificarne l'esattezza prima di procedere
                        </div>
                      </div>
                      <div style={{ textAlign: "center", fontSize: "18px" }}>
                        {geocodedAddress[0].formatted_address}
                      </div>
                    </div>
                  </Paper>
                )}
              </div> */}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={!haveAddress}
                  required={true}
                  error={haveAddress && !address.address}
                  style={{ width: "100%" }}
                  label="Indirizzo"
                  value={address.address}
                  onChange={(e) => {
                    setAddress({ ...address, ...{ address: e.target.value } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  disabled={!haveAddress}
                  error={haveAddress && !address.city}
                  style={{ width: "100%" }}
                  label="Citta"
                  value={address.city}
                  onChange={(e) => {
                    setAddress({ ...address, ...{ city: e.target.value } });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={!haveAddress}
                  required={true}
                  error={haveAddress && !address.postalCode}
                  style={{ width: "100%" }}
                  label="Cap"
                  value={address.postalCode}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      ...{ postalCode: e.target.value },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={!haveAddress}
                  required={true}
                  error={haveAddress && !address.province}
                  style={{ width: "100%" }}
                  label="Provincia"
                  value={address.province}
                  onChange={(e) => {
                    setAddress({ ...address, ...{ province: e.target.value } });
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <PrivacyConditions
              checked={privacy}
              setAccepted={(val) => {
                setPrivacy(val);
                setUserData({
                  ...userData,
                  ...{ privacy: val },
                });
              }}
              errorUnchecked={privacyUnchecked}
            ></PrivacyConditions>
          </Grid>
          <Grid item xs={12}>
            <div>Do il consenso a ricevere offerte esclusive</div>
            <div style={{ fontSize: "12px" }}>
              {props.store.AppReducers &&
                props.store.AppReducers.SpoCfg &&
                props.store.AppReducers.SpoCfg.promoConditions}
            </div>
            <RadioGroup
              aria-label="gender"
              name="privacyPromo"
              row
              value={userData.privacyPromo}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  ...{ privacyPromo: e.target.value },
                });
                setPromoUnchecked(false);
              }}
            >
              <FormControlLabel
                id="privacyPromoTrue"
                value="true"
                control={<Radio />}
                label="Si"
              />
              <FormControlLabel
                id="privacyPromoFalse"
                value="false"
                control={<Radio />}
                label="no"
              />
              <div style={{ flexBasis: "100%" }}>
                <FormHelperText style={{ color: theme.palette.error.main }}>
                  {promoUnchecked == true
                    ? "Per proseguire devi selezionare un valore per le condizioni sulle promozioni"
                    : ""}{" "}
                </FormHelperText>
              </div>
            </RadioGroup>
          </Grid>

          {/* <Grid item xs={12}>
            <div>
              Do il consenso al trattamento dei dati per attività di
              profilazione
            </div>
            <div style={{ fontSize: "12px" }}>
              {props.store.AppReducers &&
                props.store.AppReducers.SpoCfg &&
                props.store.AppReducers.SpoCfg.profilerConditions}
            </div>
            <RadioGroup
              aria-label="privacyProfiler"
              name="privacyProfiler"
              row
              value={userData.privacyProfiler}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  ...{ privacyProfiler: e.target.value },
                });
                setProfilerUnchecked(false);
              }}
            >
              <FormControlLabel
                id="privacyPromoTrue"
                value="true"
                control={<Radio />}
                label="Si"
              />
              <FormControlLabel
                id="privacyPromoFalse"
                value="false"
                control={<Radio />}
                label="no"
              />
              <div style={{ flexBasis: "100%" }}>
                <FormHelperText style={{ color: theme.palette.error.main }}>
                  {promoUnchecked == true
                    ? "Per proseguire devi selezionare un valore per le condizioni sulla profilazione"
                    : ""}{" "}
                </FormHelperText>
              </div>
            </RadioGroup>
          </Grid> */}
          <Dialog
            onClose={() => {
              setFlagConfirmAddress(false);
            }}
            aria-labelledby="simple-dialog-title"
            open={flagConfirmAddress}
          >
            <DialogTitle id="simple-dialog-title">
              Conferma indirizzo
            </DialogTitle>
            <DialogContent>
              <div>
                <div>Hai selezionato il seguente indirizzo </div>
                <div>
                  <div style={{ textAlign: "center", fontSize: "18px" }}>
                    {geocodedAddress && geocodedAddress[0].formatted_address}
                  </div>
                </div>
                <div>Vuoi confermarlo?</div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setFlagConfirmAddress(false);
                }}
                color="primary"
              >
                Annulla
              </Button>
              <Button
                onClick={() => {
                  setFlagConfirmAddress(false);
                  fetchAddUser();
                }}
                color="primary"
                autoFocus
              >
                Salva
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      ),
      next: () => {
        if (
          haveAddress &&
          (!address.address ||
            !address.city ||
            !address.postalCode ||
            !address.province)
        ) {
          NotificationManager.error(
            "Non è stato trovato nessun indirizzo valido "
          );
          return;
        }
        if (privacy == false) {
          setPrivacyUnchecked(true);
          NotificationManager.error(
            "Per proseguire devi accettare l'informativa privacy"
          );
          return;
        }

        userData.privacyProfiler = false;
        if (userData.privacyProfiler == null || userData.privacyPromo == null) {
          if (userData.privacyPromo == null) {
            NotificationManager.error(
              "Selezionare una scelta per le condizioni sulle promozioni"
            );
            setPromoUnchecked(true);
          }
          if (userData.privacyProfiler == null) {
            NotificationManager.error(
              "Selezionare una scelta per le condizioni sulla profilazione"
            );
            setProfilerUnchecked(true);
          }
          return;
        }

        // if (!geocodedAddress && haveAddress == true) {
        //   NotificationManager.error(
        //     "Non è stato trovato nessun indirizzo valido "
        //   );
        //   return;
        // }

        if (haveAddress == true && !userData.mobile) {
          setMsgCellRequired();
          setCurrentStep(currentStep - 2);
          return;
        }
        if (haveAddress) {
          fetchGeocodeAddress();
          setFlagConfirmAddress(true);
          return;
        }
        fetchAddUser();
        return;
      },
    },
  ];

  return (
    <div>
      <SpoLayoutBg
        titleHeadText={"Registrazione"}
        mainContainer={
          <Paper style={{ padding: "15px" }}>
            <Box>
              <div style={{ minHeight: "350px" }}>
                {stepArray[currentStep].container}
              </div>
              <Grid container spacing={3} justify="center">
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: "right",
                    paddingRight: 25,
                    paddingTop: 55,
                  }}
                >
                  {currentStep > 0 && (
                    <Button
                      style={{ marginRight: "10px" }}
                      color="primary"
                      variant="contained"
                      onClick={backStep}
                    >
                      Indietro
                    </Button>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      stepArray[currentStep].next();
                    }}
                  >
                    Continua
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        }
        codeImage={"WizardUser"}
        // bottomTitle={"Spesa online"}
        bottomTitle={""}
        // bottomSubtitle={"Prenota la tua spesa online"}
        bottomSubtitle={""}
      ></SpoLayoutBg>
      <Footer></Footer>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { store: state };
};
export default connect(mapStateToProps, {})(WizardUser);
// export default WizardUser;
