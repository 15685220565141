import React, { createContext, useState, useEffect, useContext } from 'react';
import 'react-router';
import { makeStyles, withStyles, useTheme, createGenerateClassName, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import '@material-ui/core/Switch';
import '@material-ui/core/FormControlLabel';
import '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { Icon, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
var UserContext = createContext({});
var defineProperty = function (obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
};
var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
var slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }
    return _arr;
  }
  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();

// import axios from "axios";

var loginIsStart = false;
function UserLogged(props) {
  var getUserApi = props.getUserApi,
    getLoginApi = props.getLoginApi;
  var _useContext = useContext(UserContext),
    user = _useContext.user,
    setUser = _useContext.setUser;
  var _useState = useState("false"),
    _useState2 = slicedToArray(_useState, 2),
    toHome = _useState2[0],
    setToHome = _useState2[1];
  useEffect(function () {
    if (loginIsStart) return;
    loginIsStart = true;
    function callback(data) {
      if (!data) {
        // setToHome(true);
        getLoginApi();
      }
      //imposta il context generico , il componente esterno alla valorizzazione capisce lo stato della login
      setUser(data);
    }
    getUserApi(callback);
    // axios.get("Account/UserContext").then(result => {

    // });
  }, []);
  return React.createElement("div", null, React.createElement("div", null, "Ciao! ", user != null ? user.name : ""));
}

// import axios from "axios";

var useStyles = makeStyles(function (theme) {
  return {
    grow: {
      flexGrow: 1
    }
  };
});
function LoginToolbar(props) {
  var props_onClickToggle = props.onClickToggle;
  var props_title = props.title;
  var props_logOutId = props.logOutId;
  var props_getUserApi = props.getUserApi;
  var props_getLogoutApi = props.getLogoutApi;
  var props_getLoginApi = props.getLoginApi;
  var props_itemMenu = props.itemMenu || null;
  var props_showMyUserArea = props.showMyUserArea == undefined ? true : props.showMyUserArea;

  //const { title, logOutId, getUserApi, getLogoutApi, getLoginApi } = props;

  var _useContext = useContext(UserContext),
    user = _useContext.user,
    setUser = _useContext.setUser;
  var classes = useStyles();
  // const classes = {};

  var _React$useState = React.useState(null),
    _React$useState2 = slicedToArray(_React$useState, 2),
    anchorEl = _React$useState2[0],
    setAnchorEl = _React$useState2[1];
  var open = Boolean(anchorEl);
  var _React$useState3 = React.useState(false),
    _React$useState4 = slicedToArray(_React$useState3, 2),
    isLogged = _React$useState4[0],
    setIsLogged = _React$useState4[1];
  var _React$useState5 = React.useState(false),
    _React$useState6 = slicedToArray(_React$useState5, 2),
    isOpenLogOut = _React$useState6[0],
    setIsOpenLogOut = _React$useState6[1];
  var _React$useState7 = React.useState({
      isConfirmedExit: false
    }),
    _React$useState8 = slicedToArray(_React$useState7, 2),
    viewModel = _React$useState8[0],
    setViewModel = _React$useState8[1];
  useEffect(function () {
    if (!user) return;
    setIsLogged(true);
  }, [user]);
  useEffect(function () {
    if (!props_logOutId) return;
    var state = _extends({}, viewModel, defineProperty({}, "logOutId", props_logOutId));
    setViewModel(state);
    handleOpenLogOut();
  }, [props_logOutId]);
  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  var handleLogOut = function handleLogOut() {
    function callback(data) {
      setViewModel(_extends({}, viewModel, data, {
        isConfirmedExit: true
      }));
    }
    if (props_getLogoutApi) {
      props_getLogoutApi(viewModel.logOutId, callback);
    }
  };
  var gotoManageAccount = function gotoManageAccount() {
    if (process && process.env && process.env.REACT_APP_AUTHORITY) {
      window.location = process.env.REACT_APP_AUTHORITY + "/Identity/Account/Manage";
    }

    //https://localhost:44342
  };
  var handleOpenLogOut = function handleOpenLogOut() {
    setIsOpenLogOut(true);
  };
  var handleCloseLogOut = function handleCloseLogOut() {
    setIsOpenLogOut(false);
  };
  return React.createElement("div", {
    className: classes.root
  }, React.createElement(AppBar, {
    position: "static"
  }, React.createElement(Toolbar, null, React.createElement(IconButton, {
    edge: "start",
    className: classes.menuButton,
    color: "inherit",
    "aria-label": "menu",
    onClick: props_onClickToggle
  }, React.createElement(MenuIcon, null)), React.createElement(Typography, {
    variant: "h6",
    className: classes.title
  }, props_title), React.createElement("div", {
    className: classes.grow
  }), React.createElement(UserLogged, {
    getUserApi: props_getUserApi,
    getLoginApi: props_getLoginApi
  }), isLogged && React.createElement("div", null, React.createElement(IconButton, {
    "aria-label": "account of current user",
    "aria-controls": "menu-appbar",
    "aria-haspopup": "true",
    onClick: handleMenu,
    color: "inherit"
  }, React.createElement(AccountCircle, null)), React.createElement(Menu, {
    id: "menu-appbar",
    anchorEl: anchorEl,
    anchorOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    keepMounted: true,
    transformOrigin: {
      vertical: "top",
      horizontal: "right"
    },
    open: open,
    onClose: handleClose
  }, props_itemMenu ? _extends({}, props_itemMenu) : null, props_showMyUserArea ? React.createElement(MenuItem, {
    onClick: gotoManageAccount
  }, "My account") : null, React.createElement(MenuItem, {
    onClick: isLogged ? handleOpenLogOut : props_getLoginApi
  }, isLogged ? "Logout" : "Login"))))), React.createElement(Dialog, {
    "aria-labelledby": "simple-modal-title",
    "aria-describedby": "simple-modal-description",
    open: isOpenLogOut,
    onClose: handleCloseLogOut
  }, React.createElement(DialogTitle, {
    id: "alert-dialog-title"
  }, "Logout"), viewModel.isConfirmedExit == false ? React.createElement("div", null, React.createElement(DialogContent, null, React.createElement(DialogContentText, null, "Sei sicuro di voler uscire?")), React.createElement(DialogActions, null, React.createElement(Button, {
    variant: "contained",
    color: "primary",
    className: classes.button,
    onClick: handleLogOut
  }, "Si"), React.createElement(Button, {
    variant: "contained",
    color: "secondary",
    className: classes.button,
    onClick: handleCloseLogOut
  }, "No"))) : React.createElement("div", null, React.createElement(DialogContent, null, React.createElement("div", null, "Sei stato sloggato"), viewModel && viewModel.PostLogoutRedirectUri ? React.createElement("div", null, "Clicca", React.createElement("a", {
    href: viewModel.PostLogoutRedirectUri
  }, "qui"), " per ritornare all'applicazione", React.createElement("span", null, viewModel.ClientName), " application.") : React.createElement(Link, {
    to: "/LoginUser"
  }, "Esci")))));
}

// import axios from "axios";

function UserLogged$1(props) {
  var getUserApi = props.getUserApi,
    getLoginApi = props.getLoginApi;
  var _React$useState = React.useState(""),
    _React$useState2 = slicedToArray(_React$useState, 2),
    name = _React$useState2[0],
    setName = _React$useState2[1];
  var props_userReducers = props.userReducers;
  function parseUserDataFromReducer() {
    return getUserApi(props_userReducers);
  }
  useEffect(function () {
    setName(helloName());
  }, [getUserApi]);

  // useEffect(() => {
  //   if (!props_userReducers) getLoginApi();
  // }, [props_userReducers]);

  var helloName = function helloName() {
    var parsed = parseUserDataFromReducer();
    return parsed != null ? parsed.name : "";
  };
  return React.createElement("div", null, React.createElement(Hidden, {
    mdDown: true
  }, React.createElement("div", null, "Ciao! ", name)));
}
var CssTextField = withStyles(function (theme) {
  return {
    root: {
      "& label.Mui-focused": {
        color: "green"
      },
      "& .MuiInput-underline:before": {
        borderBottomColor: theme.palette.common.white
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.primary.light
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottomColor: theme.palette.primary.light
      }
    }
  };
})(TextField);
var useStyles$1 = makeStyles(function (theme) {
  return {
    tbMenuClass: function tbMenuClass(props) {
      return props.horizontal ? {
        "& ul": {
          display: "flex"
        },
        "& li": {
          flexDirection: "row"
        }
      } : {};
    }
  };
});
function LoginToolbarRedux(props) {
  var theme = useTheme();
  var classes = useStyles$1(props);
  //deve essere passato l'oggetto contenente le info dell'utente
  //ade esempio props.UserReducers.profile , se passato solo props.UserReducers al suo interno ci sono
  // altre info e non è mai vuoto
  var props_userReducers = props.userReducers;
  var props_showHamburgerMenu = props.showHamburgerMenu;
  var props_onClickToggle = props.onClickToggle;
  var props_title = props.title;
  var props_logOutId = props.logOutId;
  var props_getUserApi = props.getUserApi;
  var props_getLogoutApi = props.getLogoutApi;
  var props_getLoginApi = props.getLoginApi;
  var props_itemMenu = props.itemMenu || null;
  var props_showMyUserArea = props.showMyUserArea == undefined ? true : props.showMyUserArea;
  var props_handleSearch = props.handleSearch;
  var props_textSearch = props.textSearch;
  var props_styleAppBar = props.styleAppBar;
  var props_styleToolBar = props.styleToolBar;
  var props_rightbutton = props.rightButton;
  var props_showCustomer = props.showCustomer;
  var props_horizontal = props.horizontal;
  //const { title, logOutId, getUserApi, getLogoutApi, getLoginApi } = props;

  //const classes = {};

  var _React$useState = React.useState(null),
    _React$useState2 = slicedToArray(_React$useState, 2),
    anchorEl = _React$useState2[0],
    setAnchorEl = _React$useState2[1];
  var _React$useState3 = React.useState(""),
    _React$useState4 = slicedToArray(_React$useState3, 2),
    textField = _React$useState4[0],
    setTextField = _React$useState4[1];
  var open = Boolean(anchorEl);
  var _React$useState5 = React.useState(false),
    _React$useState6 = slicedToArray(_React$useState5, 2),
    isLogged = _React$useState6[0],
    setIsLogged = _React$useState6[1];
  var _React$useState7 = React.useState(false),
    _React$useState8 = slicedToArray(_React$useState7, 2),
    isOpenLogOut = _React$useState8[0],
    setIsOpenLogOut = _React$useState8[1];
  var _React$useState9 = React.useState({
      isConfirmedExit: false
    }),
    _React$useState10 = slicedToArray(_React$useState9, 2),
    viewModel = _React$useState10[0],
    setViewModel = _React$useState10[1];
  var generateClassName = createGenerateClassName({
    productionPrefix: "tb-"
    //disableGlobal: true
  });
  var innerTheme = createMuiTheme({});
  useEffect(function () {
    if (props_textSearch) setTextField(props_textSearch);
  }, [props_textSearch]);
  useEffect(function () {
    if (!props_userReducers) return;
    setIsLogged(true);
  }, [props_userReducers]);
  useEffect(function () {
    if (!props_logOutId) return;
    var state = _extends({}, viewModel, defineProperty({}, "logOutId", props_logOutId));
    setViewModel(state);
    handleOpenLogOut();
  }, [props_logOutId]);
  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  var handleLogOut = function handleLogOut() {
    function callback(data) {
      setViewModel(_extends({}, viewModel, data, {
        isConfirmedExit: true
      }));
    }
    if (props_getLogoutApi) {
      props_getLogoutApi(viewModel.logOutId, callback);
    }
  };
  var gotoManageAccount = function gotoManageAccount() {
    if (process && process.env && process.env.REACT_APP_AUTHORITY) {
      window.location = process.env.REACT_APP_AUTHORITY + "/Identity/Account/Manage" + "?returnUrl=" + window.location.href;
    }

    //https://localhost:44342
  };
  var handleOpenLogOut = function handleOpenLogOut() {
    setIsOpenLogOut(true);
  };
  var handleCloseLogOut = function handleCloseLogOut() {
    setIsOpenLogOut(false);
  };
  var textFieldOnChange = function textFieldOnChange(e) {
    setTextField(e.target.value);
    //if (props_handleSearch) props_handleSearch(e.target.value);
  };
  return (
    // <ThemeProvider theme={innerTheme}>
    //   <StylesProvider generateClassName={generateClassName}>
    React.createElement("div", null, React.createElement(AppBar, {
      position: "static",
      style: props_styleAppBar
    }, React.createElement(Toolbar, {
      id: "back-to-top-anchor",
      style: props_styleToolBar
    }, React.createElement(Hidden, {
      mdUp: true
    }, React.createElement(IconButton, {
      edge: "start",
      color: "inherit",
      "aria-label": "menu",
      onClick: props_onClickToggle
    }, React.createElement(MenuIcon, null))), React.createElement(Typography, {
      noWrap: true,
      variant: "h6"
    }, props_title), React.createElement("div", {
      style: {
        flexGrow: 1
      }
    }), React.createElement(Hidden, {
      mdDown: true
    }, props_userReducers && props_userReducers.customer && props_showCustomer ? "Azienda: " + props_userReducers.customer + " - " : ""), props_handleSearch && React.createElement("div", null, React.createElement(Box, {
      display: {
        xs: "none",
        md: "inline"
      }
    }), React.createElement(CssTextField, {
      placeholder: "Cerca\u2026",
      InputProps: {
        //"aria-label": "search",
        style: {
          color: theme.palette.common.white
        },
        startAdornment: React.createElement(InputAdornment, {
          position: "start"
        }, React.createElement(Icon, null, "search")),
        endAdornment: React.createElement(InputAdornment, {
          position: "end"
        }, React.createElement(IconButton, {
          edge: "start",
          color: "inherit",
          onClick: function onClick() {
            props_handleSearch("");
          }
        }, React.createElement(ClearIcon, null)))
      },
      onKeyPress: function onKeyPress(ev) {
        if (ev.key === "Enter") {
          if (props_handleSearch) props_handleSearch(textField);
        }
      },
      value: textField,
      onChange: textFieldOnChange
    })), React.createElement(UserLogged$1, {
      getUserApi: props_getUserApi,
      getLoginApi: props_getLoginApi,
      userReducers: props_userReducers
    }), isLogged && React.createElement("div", null, props_rightbutton && props_rightbutton, React.createElement(IconButton, {
      onClick: handleMenu,
      color: "inherit"
    }, React.createElement(AccountCircle, null)), React.createElement(Menu, {
      className: classes.tbMenuClass,
      id: "menu-appbar",
      anchorEl: anchorEl,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right"
      },
      keepMounted: true,
      transformOrigin: {
        vertical: "top",
        horizontal: "right"
      },
      open: open,
      onClose: handleClose,
      style: {
        display: "flex"
      }
    }, props_itemMenu ? props_itemMenu : null, props_showMyUserArea ? React.createElement(MenuItem, {
      onClick: gotoManageAccount
    }, "My account") : null, React.createElement(MenuItem, {
      onClick: isLogged ? handleOpenLogOut : props_getLoginApi
    }, isLogged ? "Logout" : "Login"))))), React.createElement(Dialog, {
      "aria-labelledby": "simple-modal-title",
      "aria-describedby": "simple-modal-description",
      open: isOpenLogOut,
      onClose: handleCloseLogOut
    }, React.createElement(DialogTitle, {
      id: "alert-dialog-title"
    }, "Logout"), viewModel.isConfirmedExit == false ? React.createElement("div", null, React.createElement(DialogContent, null, React.createElement(DialogContentText, null, "Sei sicuro di voler uscire?")), React.createElement(DialogActions, null, React.createElement(Button, {
      variant: "contained",
      color: "primary",
      onClick: handleLogOut
    }, "Si"), React.createElement(Button, {
      variant: "contained",
      color: "secondary",
      onClick: handleCloseLogOut
    }, "No"))) : React.createElement("div", null, React.createElement(DialogContent, null, React.createElement("div", null, "Sei stato sloggato"), viewModel && viewModel.PostLogoutRedirectUri ? React.createElement("div", null, "Clicca", React.createElement("a", {
      href: viewModel.PostLogoutRedirectUri
    }, "qui"), " per ritornare all'applicazione", React.createElement("span", null, viewModel.ClientName), " application.") : React.createElement(Link, {
      to: "/LoginUser"
    }, "Esci")))))
    //   </StylesProvider>
    // </ThemeProvider>
  );
}
export default LoginToolbar;
export { UserContext, LoginToolbarRedux };