import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import {
  SaveStateApp,
  SetInfoCart,
  fetchCfgShopSpo,
  setStatusUserShopList,
} from "../redux/actions";

import { GET, POST, DELETE, PUT } from "request-helper";

import Viewport from "../components/Viewport";
import Footer from "../components/Footer";
import LayoutWithHead from "../components/LayoutWithHead";
import CardProduct from "../components/Product/CardProduct";
import GridProduct from "../components/Product/GridProduct";
import { roundTwoDigit } from "../utility/utility";
import DialogLoader from "../components/DialogLoader";
import Logo from "../components/Logo";
import DeliverySummary from "../components/Product/DeliverySummary";
import CustomIcon from "../components/CustomIcon";
import ProductCounter from "../components/Product/ProductCounter";
import DialogListShopList from "../components/Product/DialogListShopList";

import {
  Tooltip,
  OutlinedInput,
  Divider,
  ListItemIcon,
  Typography,
  Zoom,
  useScrollTrigger,
  Button,
  Paper,
  Grid,
  Box,
  Tabs,
  Tab,
  Fab,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  IconButton,
  CircularProgress,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Moment from "react-moment";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faList,
  faTimes,
  faStoreAlt,
  faCalendarAlt,
  faMapMarkedAlt,
  faTags,
  faShoppingBag,
  faPercent,
  faHeart,
  faShoppingCart,
  faListAlt,
  faEye,
  faBook,
  faCartPlus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { FormattedNumber } from "react-intl";
import ContentLeftBar from "../components/Product/ContentLeftBar";
import { NotificationManager } from "react-notifications";
import DialogWithContent from "../components/DialogWithContent";

library.add(fas);
library.add(fab);

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    width: "90%",
    // height: "60%",
    maxHeight: "60%",
    // minHeight: "200px",
    left: "5% !important",
    "& ul": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  buttomDepartment: {
    marginLeft: theme.spo.mXs,
    marginRight: theme.spo.mXs,
    marginBottom: theme.spo.mXs,
    boxShadow: "0px 0px 12px 0px #ccc",
    borderRadius: "50px",
    // marginBottom: "15px",
    "&:hover": {
      transform: "translateY(-4px)",
      backgroundColor: "#ccc",
      transition: "transform 0.2s",
    },
  },

  shoOnMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  showOnSm: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  tabSectionProduct: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideExpandBarMd: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideExpandBarXs: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    // [theme.breakpoints.down("md")]: {
    //   display: "none",
    // },
  },

  widthLeft: {
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "50px",
    },
  },

  widthLeftCollapsed: {
    width: "50px",
  },

  widthRightFull: {
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 50px)",
    },
    width: "calc(100% - 250px)",
  },
  widthRight: {
    // width: `calc(100% - ${drawerWidth}px)`,
    width: "calc(100% - 50px)",
  },

  drawerWidth: {
    width: 250,
  },
}));

const Product = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const productLabel = "Prodotti";
  const favoriteLabel = "Preferiti";
  const buyLabel = "Acquistati";
  const promoLabel = "Promozioni";
  const listLabel = "Lista della spesa";

  //desc = 0 , asc = 1
  const orderByObject = [
    {
      description: "A-Z crescente",
      order: 1,
      field: "Name",
    },
    {
      description: "A-Z decrescente",
      order: 0,
      field: "Name",
    },
    {
      description: "Prezzo crescente",
      order: 1,
      field: "Price",
    },
    {
      description: "Prezzo decrescente",
      order: 0,
      field: "Price",
    },
    {
      description: "Marca crescente",
      order: 1,
      field: "Marca",
    },
    {
      description: "Marca decrescente",
      order: 0,
      field: "Marca",
    },
  ];

  const [isEmpty, setIsEmpty] = React.useState(true);
  const [isReload, setIsReload] = React.useState(false);
  const [typingTimeout, setTypingTimeout] = React.useState({
    value: "",
    idTimeOut: 0,
  });

  //TODO da togliere , prendere i dati dal CartReducers
  const [deliveryDetail, setDeliveryDetail] = React.useState(null);

  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    React.useState(false);
  const [altriRisultati, setAltriRisultati] = React.useState(false);

  const [currentValueTab, setCurrentValueTab] = React.useState(0);

  const [listFavorite, setListFavorite] = React.useState(null);

  const [loadingProduct, setLoadingProduct] = React.useState(false);

  //true card false grid
  const [layoutCard, setLayoutCard] = React.useState(true);

  const [collapsedLeft, setCollapsedLeft] = React.useState(true);

  const [orderBySelect, setOrderBySelect] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState(null);

  const [listBrand, setListBrand] = React.useState(null);
  const [brand, setBrand] = React.useState({});

  const allDepartment = {
    idCatWeb: "ALL",
    desCatWeb: "TUTTE",
    icon: "clipboard-list",
  };
  const [listDepartment, setListDepartment] = React.useState(null);
  const [department, setDepartment] = React.useState(allDepartment);
  const [departmentFirstSelection, setDepartmentFirstSelection] =
    React.useState(allDepartment);

  const [menuListCategory, setMenuListCategory] = React.useState(null);
  const [listCategory, setListCategory] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [idCategory, setIdCategory] = React.useState(null);

  const [textSearch, setTextSearch] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  var refDepartment = useRef();
  const [anchorElHamburgerMenu, setAnchorElHamburgerMenu] =
    React.useState(null);
  const [drawer, setDrawer] = React.useState({ left: false });

  var searchFilterObjectEmpty = {
    idDepartment: null, //int?
    idcategory: null, //int?
    orderBy: null, //int?
    orderField: null, //string
    textSearch: null, //string
    filterBrand: null, //string
    typeOfSearch: 0, //int
    pageNumber: 0, //int?
    pageSize: 20, //int?
  };

  var textValueCurrent = "";

  const [searchFilterProduct, setSearchFilterProduct] = React.useState(null);

  const [listProduct, setListProduct] = React.useState(null);

  const [currentTitle, setCurrentTitle] = React.useState(productLabel);

  const [shopListUser, setShopListUser] = React.useState({
    list: [], //elenco delle liste
    selected: null, // lista selezionata
    nameNewList: "", // nome nuova lista
    openDialogNewList: false, //dialog per la creazione di una nuova lista
    listProduct: null, //lista dei prodotti della lista
    productQuantity: 1, // quantità selezionata
    openDialogProductQuantity: null, //dialog per selezione quantità da aggiungere al carrello
    dialogSelectShopList: false, // dialog per la selezione di una lista esistente
    dialogAddToCartQuestion: false, //dialog per la selezione della replaceAction per la generazione carrello
  });
  //valorizzata se ci sono dei prodotti non in assortimento quando si aggiunge una lista al carrello
  const [dialogProductNotInAssortiment, setDialogProductNotInAssortiment] =
    React.useState(false);
  const [currentIdArea, setCurrentIdArea] = React.useState(null);
  const [scrollDisable, setScrollDisable] = React.useState(false);

  window.onscroll = () => {
    //blocca lo scroll
    if (scrollDisable) {
      var x = window.scrollX;
      var y = window.scrollY;
      window.onscroll = function () {
        window.scrollTo(x, y);
      };
    }
    // const {
    //   loadUsers,
    //   state: { error, isLoading, hasMore },
    // } = this;

    // Bails early if:
    // * there's an error
    // * it's already loading
    // * there's nothing left to load
    // if (error || isLoading || !hasMore) return;
    if (!searchFilterProduct) return;
    if (disableInfiniteScroll) return;
    // Checks that the page has scrolled to the bottom
    if (
      // window.innerHeight + document.documentElement.scrollTop ===
      // document.documentElement.offsetHeight
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 300
    ) {
      if (!loadingProduct) {
        document.activeElement.blur();
        setSearchFilterProduct({
          ...searchFilterProduct,
          ...{ pageNumber: searchFilterProduct.pageNumber + 1 },
        });
        setScrollDisable(true);
        console.log("scroll disable");
      }
      console.log("scroll");
    }
  };

  //TODO da togliere , prendere i dati dal CartReducers
  const fetchDeliveryDetail = () => {
    let param = {
      shopId: props.store.CartReducers.shopId,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/GetCartDeliveryDetail",
      null,
      param,
      true
    ).then((data) => {
      setDeliveryDetail(data);
    });
  };

  const fetchListFavorite = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListfavoriteByUser",
      null,
      {},
      true
    ).then((data) => {
      setListFavorite(data);
    });
  };

  const fetchListDepartment = (searchFilter) => {
    return POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListDepartment",
      searchFilter || searchFilterProduct,
      null,
      true
    ).then((data) => {
      if (data === null || !data.length) return;
      let dep =
        process.env.REACT_APP_ALL_DEPART_AFTER === "true"
          ? [...data, ...[allDepartment]]
          : [...[allDepartment], ...data];
      setListDepartment(dep);
      return dep;
    });
  };

  const fetchListCategory = (depart, forMenu) => {
    let dep = depart || department;
    if (dep.idCatWeb == null) {
      setListCategory(null);
      return;
    }

    let filter = searchFilterProduct || searchFilterObjectEmpty;
    filter.idcategoryForMenuList = dep.idCatWeb;
    setSearchFilterProduct(filter);

    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListCategory",
      filter,
      null,
      true
    ).then((data) => {
      if (data && data.length) {
        data.push({ idCatWeb: "ALL", desCatWeb: "Tutti i prodotti" });
      }
      if (!forMenu) {
        setListCategory(data);
        return;
      }
      setMenuListCategory({
        department: dep,
        category: data,
      });
    });
  };

  const fetchListProduct = (updateListProduct) => {
    setLoadingProduct(true);
    if (props.store.CartReducers && props.store.CartReducers.shopId) {
      let param = null;
      if (updateListProduct) {
        var d = {
          ...searchFilterProduct,
          idDepartment: null,
          idcategory: null,
          textSearch: null,
          filterBrand: null,
          pageNumber: null,
          pageSize: null,
          idcategoryForMenuList: null,
          idList: shopListUser.selected,
          typeOfSearch: 4,
        };
        param = {
          codPv: props.store.CartReducers.shopId,
          search: d,
        };
      } else {
        param = {
          codPv: props.store.CartReducers.shopId,
          search: searchFilterProduct,
        };
      }
      POST(
        process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListProduct",
        param,
        null,
        true
      ).then((data) => {
        if (updateListProduct) {
          setShopListUser({ ...shopListUser, listProduct: data });
          //aggiorno prima i dati della lista (luistProduct) poi aggiorno la pagina con i filtri
          setSearchFilterProduct({
            ...searchFilterProduct,
            pageNumber: 0,
            idList: shopListUser.selected,
          });
          return;
        }
        if (data && !data.length) {
          setDisableInfiniteScroll(true);
        }

        setLoadingProduct(false);
        var dataToDisplay = [];
        if (searchFilterProduct.pageNumber == 0) {
          dataToDisplay = data;
        } else {
          dataToDisplay = [...listProduct, ...data];
        }

        setListProduct(dataToDisplay.filter((c) => c.f_ATTIVO));

        if (data.length > 0 && dataToDisplay && dataToDisplay.length > 100) {
          setAltriRisultati(true);
          setDisableInfiniteScroll(true);
        } else setAltriRisultati(false);
      });
    }
  };

  const fetchListBrand = () => {
    //if (props.store.CartReducers && props.store.CartReducers.shopId) {

    POST(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/ListBrandForSelectedFilter",
      searchFilterProduct,
      null,
      true
    ).then((data) => {
      setListBrand(data);
    });
    //}
  };

  const fetchAddToCart = (product) => {
    let param = {
      shopId: props.store.CartReducers.shopId,
      Product: {
        idProduct: product.coD_RIORD,
        quantity: product.qtaBase,
        price: roundTwoDigit(product.promoPrice || product.price),
        amount: roundTwoDigit(
          (product.promoPrice || product.price) * product.qtaBase
        ),
      },
    };
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/addToCart",
      param,
      null,
      true
    ).then((data) => {
      if (!props.store.CartReducers.webSpoProductInCart)
        props.store.CartReducers.webSpoProductInCart = [];
      param.Product.vwWebSpoProduct = product;
      props.store.CartReducers.webSpoProductInCart.push(param.Product);
      props.SetInfoCart(props.store.CartReducers);
      NotificationManager.success(
        product.description + " aggiunto nel carrello"
      );
    });
  };

  const fetchRemoveFromCart = (product) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveFromCart?shopId=" +
        props.store.CartReducers.shopId +
        "&idProduct=" +
        product.coD_RIORD,
      null,
      null,
      true
    ).then((data) => {
      if (!props.store.CartReducers.webSpoProductInCart)
        props.store.CartReducers.webSpoProductInCart = [];
      props.store.CartReducers.webSpoProductInCart =
        props.store.CartReducers.webSpoProductInCart.filter((c) => {
          return c.idProduct !== product.coD_RIORD;
        });
      props.SetInfoCart(props.store.CartReducers);
    });
  };

  const fetchRemoveAllFromCart = (product) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveAllFromCart?shopId=" +
        props.store.CartReducers.shopId,
      null,
      null,
      true
    ).then((data) => {
      props.store.CartReducers.webSpoProductInCart = [];
      props.SetInfoCart(props.store.CartReducers);
    });
  };

  const fetcUpdateQuantityProduct = (product, quantity, isInShopList) => {
    let promise = null;
    if (currentIdArea == 4 && isInShopList) {
      //se sono nell'area 4 non devo aumentare i pezzi nel carrello ma i pezzi nella lista della spesa selezionata
      promise = PUT(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/UpdateQuantityProductInList?idList=" +
          shopListUser.selected +
          "&prodCode=" +
          product.coD_RIORD +
          "&quantity=" +
          quantity,
        null,
        null,
        true
      );
    } else {
      promise = PUT(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/UpdateQuantityToCart?shopId=" +
          props.store.CartReducers.shopId +
          "&idProduct=" +
          product.coD_RIORD +
          "&quantity=" +
          quantity,
        null,
        null,
        true
      );
    }
    promise.then((data) => {
      if (currentIdArea == 4) {
        var newList = shopListUser.listProduct.map((c) => {
          if (c.coD_RIORD == product.coD_RIORD) {
            c.quantity = quantity;
          }
          return c;
        });
        setShopListUser({ ...shopListUser, listProduct: newList });
      } else {
        var res = props.store.CartReducers.webSpoProductInCart.filter(
          (c) => c.idProduct === product.coD_RIORD
        );
        if (res[0]) {
          res[0].quantity = quantity;
          props.SetInfoCart(props.store.CartReducers);
        }
      }
    });
  };

  const fetchSetFavorite = (product) => {
    let res = listFavorite.filter((c) => c.idProduct == product.coD_RIORD);
    var promise = null;
    var isRemove = false;
    //il prodotto è già tra i favoriti devo toglierlo
    if (res[0]) {
      isRemove = true;
      promise = DELETE(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/RemoveProductToFavorite?idProduct=" +
          product.coD_RIORD,
        null,
        null,
        true
      );
    }
    //il prodotto non è tra i favoriti devo aggiungerlo
    else {
      promise = POST(
        process.env.REACT_APP_URL_API_SPO +
          "v1/Prenotation/AddProductToFavorite",
        // { idProduct: product.coD_RIORD },
        { idProduct: product.coD_RIORD },
        null,
        true
      );
    }
    promise.then((data) => {
      let list = null;
      if (isRemove) {
        list = listFavorite.filter((c) => c.idProduct != product.coD_RIORD);
      } else {
        list = listFavorite;
        list.push({ idProduct: product.coD_RIORD });
      }
      setListFavorite([...list]);
    });
  };

  const handleClickAddToShopList = (product, count) => {
    if (!shopListUser.selected) {
      //non ci sono liste devo crearle
      if (!shopListUser.list || shopListUser.list.length == 0) {
        setShopListUser({
          ...shopListUser,
          openDialogNewList: { product, count },
        });
        return;
      }
      setShopListUser({
        ...shopListUser,
        dialogSelectShopList: { product, count },
      });
      return;
    }

    let res = null;
    res = shopListUser.listProduct
      ? shopListUser.listProduct.filter((c) => c.coD_RIORD == product.coD_RIORD)
      : [];
    if (!res) return;

    if (currentIdArea == 4 || res[0]) {
      fetchAddToShopList(product, count);
      return;
    }
    setShopListUser({
      ...shopListUser,
      openDialogProductQuantity: product,
      productQuantity: product.qtaBase,
    });
  };

  const fetchAddToShopList = (productParam, countParam, notifyDelete) => {
    let res = null;

    let product = productParam || shopListUser.openDialogProductQuantity;
    let count = countParam || shopListUser.productQuantity;
    let promise = null;
    let isRemove = false;
    let listSel = shopListUser.selected;

    res = shopListUser.listProduct
      ? shopListUser.listProduct.filter((c) => c.coD_RIORD == product.coD_RIORD)
      : [];

    if (!res) return;
    //il prodotto è già nella lista della spesa i favoriti devo toglierlo
    if (res[0]) {
      if (!notifyDelete) {
        isRemove = true;
        promise = DELETE(
          process.env.REACT_APP_URL_API_SPO +
            "v1/Prenotation/RemoveProductFromList?idProduct=" +
            product.coD_RIORD +
            "&idList=" +
            listSel,
          null,
          null,
          true
        );
      } else {
        NotificationManager.success(
          "Il prodotto è già nella lista selezionata"
        );
        return;
      }
    }
    //il prodotto non è tra i favoriti devo aggiungerlo
    else {
      promise = POST(
        process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/AddProductToList",
        { idList: listSel, idProduct: product.coD_RIORD, quantity: count },

        null,
        true
      );
    }
    promise.then((data) => {
      let shopListDesc = shopListUser.list.filter((c) => c.id == listSel)[0]
        .description;
      let listProd = null;
      if (isRemove) {
        listProd = shopListUser.listProduct.filter(
          (c) => c.coD_RIORD != product.coD_RIORD
        );
        NotificationManager.success(
          product.description + " rimosso dalla lista " + shopListDesc
        );
      } else {
        listProd = shopListUser.listProduct || [];
        listProd.push({ coD_RIORD: product.coD_RIORD });
        NotificationManager.success(
          product.description + " aggiunto alla lista " + shopListDesc
        );
      }
      setShopListUser({
        ...shopListUser,
        listProduct: [...listProd],
        openDialogProductQuantity: null,
      });
    });
  };

  const fetchListUserShopList = (afterCreate) => {
    return GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/ListUserList",
      null,
      null,
      true
    ).then((data) => {
      //if (data == null || Object.keys(data).length === 0) return;
      let el = Object.keys(data).map((c) => {
        return { id: c, description: data[c] };
      });

      let lu = {
        ...shopListUser,
        nameNewList: "",
        list: el,
      };
      if (el.length == 0) {
        lu.selected = "";
      }
      if (el.length > 0 && afterCreate) {
        let sel = el.filter(
          (c) => c.description == shopListUser.nameNewList
        )[0];
        lu.selected = sel.id;
      }
      if (shopListUser.openDialogNewList === true) {
        lu.openDialogNewList = false;
      }
      setShopListUser(lu);
    });
  };

  const fetchListUserAdd = () => {
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/CreateList",
      { Name: shopListUser.nameNewList },
      null,
      true
    ).then((data) => {
      fetchListUserShopList(true);
    });
  };

  const fetchUserRemoveShopList = (idList) => {
    return DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/Prenotation/RemoveList?idList=" +
        idList,
      null,
      true
    ).then((data) => {
      fetchListUserShopList();
    });
  };

  const fetchCartSummary = () => {
    let param = {
      shopId: props.store.CartReducers.shopId,
    };
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/GetCartSummary",
      null,
      param,
      true
    ).then((data) => {
      props.SetInfoCart(data);
      //props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/summary");
    });
  };

  const handleClickAddToCartList = (id) => {
    if (
      !props.store.CartReducers.webSpoProductInCart ||
      props.store.CartReducers.webSpoProductInCart.length == 0
    ) {
      //non c'è nessun articolo nel carrello non devo fare nessuna domanda
      //sovrascrivo dui default
      fetchAddListToCart(id);
      return;
    }
    setShopListUser({ ...shopListUser, dialogAddToCartQuestion: { id: id } });
  };

  const fetchAddListToCart = (id, replaceAction) => {
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/Prenotation/AddListToCart",
      {
        IdList: id,
        // IdList: shopListUser.selected,
        ShopId: props.store.CartReducers.shopId,
        ReplaceAction: replaceAction === undefined ? 3 : replaceAction,
      },
      null,
      true
    ).then((data) => {
      if (data && data.length > 0) {
        //ci sono alcuni prodotti che non sono + in assortimento visualizza la dialog
        setDialogProductNotInAssortiment(data);
        return;
      }
      fetchCartSummary();
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseHamburgerMenu = () => {
    setAnchorElHamburgerMenu(null);
  };

  const onChangeSelectCategory = (e) => {
    let cat = listCategory.filter((c) => {
      return c.idCatWeb == e.target.value;
    });
    if (!cat) return;
    clickMenuCategory(cat[0]);
  };

  const clickMenuCategory = (category, department) => {
    setAnchorEl(null);
    let idDepartment = null;
    let cat = null;
    if (category) {
      setDepartment(department || menuListCategory.department);
      setCategory(category);
      setListCategory(menuListCategory?.category);
      setIdCategory(category.idCatWeb);

      cat = category.idCatWeb == "ALL" ? null : category.idCatWeb;
      idDepartment =
        department?.idCatWeb || menuListCategory.department.idCatWeb;
    } else {
      setCategory(null);
      setIdCategory(null);
      setListCategory(null);
    }

    setCollapsedLeft(false);

    setListBrand(null);
    setBrand({});
    setTextSearch("");
    setOrderBy(null);
    // setOrderField(null);

    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{
        idDepartment: idDepartment,
        idcategory: cat,
        orderBy: null,
        orderField: null,
        textSearch: null,
        filterBrand: null,
        pageNumber: 0,
      },
    });
  };

  const handleClickDepartment = (department, event) => {
    setDepartmentFirstSelection(department);

    if (department.countCat === 0) {
      clickMenuCategory({ idCatWeb: "ALL" }, department);
      setDepartment(department);
      return;
    }

    //ho cliccato su  TUTTE
    if (department.idCatWeb == "ALL") {
      clickMenuCategory();
      setDepartment(department);
      return;
    }

    setListCategory(null);
    setMenuListCategory(null);

    fetchListCategory(department, true);
    var ct = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(ct);
    }, 200);
  };

  const tabInfo = {
    0: {
      currentTitle: productLabel,
    },
    1: {
      currentTitle: favoriteLabel,
    },
    2: {
      currentTitle: buyLabel,
    },
    3: {
      currentTitle: promoLabel,
    },
    4: {
      currentTitle: listLabel,
      emptyInfo: (
        <div style={{ color: theme.spo.darkGray.color }}>
          In quest'area vengono visualizzate le tue liste della spesa.
          <ul>
            <li>
              Premi il pulsante "Nuova Lista della spesa" nella barra laterale,
            </li>
            <li>
              vai nella sezione dei{" "}
              <FontAwesomeIcon icon={faTags}></FontAwesomeIcon> PRODOTTI
            </li>
            <li>
              Aggiungi i prodotti alla tua lista premendo il pulsante{" "}
              <FontAwesomeIcon icon={faBook}></FontAwesomeIcon>
            </li>
            <li>
              Una volta terminata la tua lista della spesa torna nella sezione{" "}
              <FontAwesomeIcon icon={faBook}></FontAwesomeIcon> LISTE ,seleziona
              la lista e premi il pulsante
              <FontAwesomeIcon icon={faCartPlus} /> per aggiungere gli articoli
              della lista nel tuo carrello
            </li>
          </ul>
        </div>
      ),
    },
  };

  const onChangeTypeOfSearch = (idType) => {
    setCurrentIdArea(idType);
    var tInfo = tabInfo[idType];

    switch (idType) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        setCollapsedLeft(false);
        if (searchFilterProduct) {
          searchFilterProduct.textSearch = "";
        }
        break;
    }
    setCurrentTitle(tInfo.currentTitle);
    setListBrand(null);
    setBrand({});
    setTextSearch("");
    setOrderBy(null);
    setCategory(null);
    setListCategory(null);
    setDepartment(allDepartment);
    setIdCategory(null);
    if (searchFilterProduct == null)
      searchFilterProduct = searchFilterObjectEmpty;
    searchFilterProduct.pageNumber = 0;
    searchFilterProduct.typeOfSearch = idType;
    searchFilterProduct.filterBrand = null;
    searchFilterProduct.idDepartment = null;
    searchFilterProduct.idcategory = null;
    searchFilterProduct.orderBy = null;
    searchFilterProduct.orderField = null;
    setSearchFilterProduct({ ...searchFilterProduct });
  };

  const handleClickNewList = () => {
    setShopListUser({ ...shopListUser, openDialogNewList: true });
  };

  const calculateTotalCart = () => {
    var total =
      props.store.CartReducers &&
      props.store.CartReducers.webSpoProductInCart &&
      props.store.CartReducers.webSpoProductInCart.reduce &&
      props.store.CartReducers.webSpoProductInCart.reduce(
        (accumul, prod) => accumul + roundTwoDigit(prod.price * prod.quantity),
        0
      );
    if (total == undefined || total == null) total = 0;
    return total;
  };

  const redirectToWizardCart = () => {
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardCart");
  };

  const showDescriptionShopListUser = () => {
    if (shopListUser.list && shopListUser.selected) {
      var l = shopListUser.list.filter((c) => c.id == shopListUser.selected);
      if (l.length > 0) {
        return l[0].description;
      }
    }
    return null;
  };

  function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#back-to-top-anchor"
      );

      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };

    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </div>
      </Zoom>
    );
  }

  const firstLoad = () => {
    let objEmpty = props?.store?.AppReducers?.stateSaved?.product
      ? null
      : searchFilterObjectEmpty;

    if (process.env.REACT_APP_IDCATWEB_SELECT && objEmpty) {
      fetchListDepartment(objEmpty).then((data) => {
        let r = data?.filter(
          (c) => c.idCatWeb === parseInt(process.env.REACT_APP_IDCATWEB_SELECT)
        );
        if (r && r[0]) {
          objEmpty.idDepartment = r[0].idCatWeb;
          handleClickDepartment(r[0]);
        }
        setSearchFilterProduct(objEmpty);
        fetchListFavorite();
      });
      return;
    }

    //il filtro iniziale se c'è uno stato salvato deve essere null
    setSearchFilterProduct(objEmpty);
    fetchListFavorite();

    if (props?.store?.AppReducers && !props.store.AppReducers.SpoShopCfg) {
      props.fetchCfgShopSpo(props.store.CartReducers.shopId);
    }
  };

  useEffect(() => {
    if (shopListUser.selected === null || shopListUser.selected === undefined)
      return;

    fetchListProduct(true);
  }, [shopListUser.selected]);

  useEffect(() => {
    if (shopListUser.dialogSelectShopList) {
      var d = { ...shopListUser.dialogSelectShopList };
      shopListUser.dialogSelectShopList = null;
      setShopListUser(shopListUser);
      // setShopListUser({ ...shopListUser, dialogSelectShopList: null });
      fetchAddToShopList(d.product, d.count, true);
    }

    if (shopListUser.openDialogNewList) {
      var d = { ...shopListUser.openDialogNewList };
      shopListUser.openDialogNewList = null;
      //apro la dialog per selezionare la quantità
      shopListUser.openDialogProductQuantity = d.product;
      setShopListUser(shopListUser);
      // setShopListUser({ ...shopListUser, dialogSelectShopList: null });
      //fetchAddToShopList(d.product, d.count);
    }
  }, [shopListUser.listProduct]);

  //afterRender
  useEffect(() => {
    setScrollDisable(false);

    console.log("scroll enable");
  });

  useEffect(() => {
    if (!props.store.CartReducers) return;
    if (props.store.CartReducers.shopId) {
      searchFilterObjectEmpty.shopId = props.store.CartReducers.shopId;

      //lo fa solo la prima volta cioè se listDepartment è null
      //e carica al primo avvio i dati
      if (listDepartment == null) {
        firstLoad();
      }
      if (deliveryDetail == null) fetchDeliveryDetail();
    } else {
      props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
    }
  }, [props.store.CartReducers]);

  //afterRender
  useEffect(() => {
    // enableScroll();
  });

  useEffect(() => {
    if (props.store.CartReducers == null) {
      props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/home");
    }

    //resume old state
    if (
      props.store.AppReducers &&
      props.store.AppReducers.stateSaved &&
      props.store.AppReducers.stateSaved.product
    ) {
      let oldState = props.store.AppReducers.stateSaved.product;
      if (oldState) {
        setTimeout(() => {
          var lastFilter = oldState.searchFilterProduct;
          if (lastFilter == null || lastFilter == undefined)
            lastFilter = searchFilterObjectEmpty;
          lastFilter.pageNumber = 0;
          if (!lastFilter.shopId)
            lastFilter.shopId = props.store.CartReducers.shopId;
          setIsReload(true);
          setTextSearch(oldState.textSearch);
          setIdCategory(oldState.idCategory);
          setDepartment(oldState.department);
          setCategory(oldState.category);
          setListCategory(oldState.listCategory);
          setCurrentValueTab(oldState.currentValueTab);
          setOrderBy(oldState.orderBy);
          setOrderBySelect(oldState.orderBySelect);
          setCollapsedLeft(oldState.collapsedLeft);
          setLayoutCard(oldState.layoutCard);
          setListBrand(oldState.listBrand);
          setBrand(oldState.brand);
          setSearchFilterProduct(lastFilter);
          setIsReload(false);
          setShopListUser({
            ...shopListUser,
            listProduct: oldState.shopListProduct,
            selected: oldState.idList,
          });
          setCurrentIdArea(oldState.idType);
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    if (category == null) return;
    if (isReload) return;
    setBrand({});
    let cat = category.idCatWeb == "ALL" ? null : category.idCatWeb;
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ idcategory: cat, filterBrand: null, pageNumber: 0 },
    });
  }, [category]);

  useEffect(() => {
    if (orderBy == null) return;
    if (isReload) return;
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ orderBy: orderBy.order, orderField: orderBy.field },
    });
  }, [orderBy]);

  const changeText = (val) => {
    if (val == null) return;
    searchFilterProduct.pageNumber = 0;
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ textSearch: val },
    });
  };

  useEffect(() => {
    if (brand == null || Object.keys(brand).length === 0) return;
    if (isReload) return;
    let brandname = "";
    for (var name in brand) {
      if (brand[name]) {
        if (brandname) brandname += ",";
        brandname += name;
      }
    }
    setSearchFilterProduct({
      ...searchFilterProduct,
      ...{ filterBrand: brandname, pageNumber: 0 },
    });
  }, [brand]);

  useEffect(() => {
    if (searchFilterProduct == null) return;
    fetchListProduct();
    if (category) fetchListBrand();
    fetchListDepartment();
    fetchListUserShopList();
    setDisableInfiniteScroll(false);

    if (!isEmpty) {
      //salvo lo stato attuale della pagina prodotti
      var stateSaved = {
        searchFilterProduct,
        textSearch,
        idCategory,
        category,
        listCategory,
        department,
        brand,
        listBrand,
        orderBy,
        orderBySelect,
        collapsedLeft,
        layoutCard,
        currentValueTab,
        idList: searchFilterProduct.idList,
        shopListProduct: shopListUser.listProduct,
        idType: currentIdArea,
      };
      props.SaveStateApp({
        page: "product",
        state: stateSaved,
      });
    }
    setIsEmpty(false);
  }, [searchFilterProduct]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  return (
    <div id="container-product">
      <DialogLoader show={!listProduct}></DialogLoader>
      {listProduct && (
        <div id="container-product-layout">
          <Menu
            id="hamburger-menu"
            anchorEl={anchorElHamburgerMenu}
            keepMounted
            open={Boolean(anchorElHamburgerMenu)}
            onClose={handleCloseHamburgerMenu}
          >
            {deliveryDetail && (
              <div>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faShoppingBag}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {deliveryDetail.deliveryMode}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faMapMarkedAlt}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {deliveryDetail.deliveryAddress}{" "}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faStoreAlt}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {deliveryDetail.shopInfo} {deliveryDetail.shopAddress}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
                  </ListItemIcon>
                  <Typography>
                    <Moment format="DD-MM-YYYY">
                      {deliveryDetail.dateDelivery}
                    </Moment>{" "}
                    <Moment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                      {deliveryDetail.timeStart}
                    </Moment>{" "}
                    -{" "}
                    <Moment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faShoppingBag}></FontAwesomeIcon>
                      </ListItemIcon>
                      {deliveryDetail.timeEnd}
                    </Moment>
                  </Typography>
                </MenuItem>
                <Divider></Divider>
              </div>
            )}
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(0);
                handleCloseHamburgerMenu();
              }}
            >
              {productLabel}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(1);
                handleCloseHamburgerMenu();
              }}
            >
              {favoriteLabel}
            </MenuItem>
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(2);
                handleCloseHamburgerMenu();
              }}
            >
              {buyLabel}
            </MenuItem>
            {process.env.REACT_APP_SHOW_PROMOTION === "true" && (
              <MenuItem
                onClick={() => {
                  onChangeTypeOfSearch(3);
                  handleCloseHamburgerMenu();
                }}
              >
                {promoLabel}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                onChangeTypeOfSearch(4);
                handleCloseHamburgerMenu();
              }}
            >
              {listLabel}
            </MenuItem>
          </Menu>
          {loadingProduct && (
            <Fab
              style={{
                position: "fixed",
                bottom: theme.spacing(2),
                right: theme.spacing(2),
              }}
            >
              <CircularProgress></CircularProgress>
            </Fab>
          )}
          <LayoutWithHead
            onClickToggle={(e) => {
              setAnchorElHamburgerMenu(e.currentTarget);
            }}
            rowLogoLeft={<Logo></Logo>}
            rowLogoRight={
              <Grid container>
                <DeliverySummary
                  deliveryDetail={deliveryDetail}
                ></DeliverySummary>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  style={{
                    textAlign: "right",
                    paddingRight: theme.spo.pSm,
                  }}
                >
                  {/* <DialogConfirm
                    title={"Elimina"}
                    text={"Sei sicuro di voler svuotare il carrello?"}
                  >
                    {(confirm) => (
                      <Tooltip title={"Svuota il carrello"}>
                        <IconButton
                          disabled={
                            !props.store.CartReducers ||
                            !props.store.CartReducers.webSpoProductInCart ||
                            props.store.CartReducers.webSpoProductInCart
                              .length == 0
                          }
                          aria-label="status"
                          onClick={confirm(() => {
                            fetchRemoveAllFromCart();
                          })}
                          style={theme.spo.product.iconHeadTrash}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </DialogConfirm> */}
                  <Tooltip title={"Vai al carrello"}>
                    <IconButton
                      disabled={
                        !props.store.CartReducers ||
                        !props.store.CartReducers.webSpoProductInCart ||
                        props.store.CartReducers.webSpoProductInCart.length == 0
                      }
                      aria-label="status"
                      onClick={() => {
                        props.history.push(
                          process.env.REACT_APP_BASE_PATH_ROUTE + "/Summary"
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faShoppingCart}
                        style={theme.spo.product.iconHeadTrash}
                      />
                    </IconButton>
                  </Tooltip>
                  {props.store &&
                  props.store.AppReducers &&
                  props.store.AppReducers.SpoCfg &&
                  props.store.AppReducers.SpoCfg.showCountPiece ? (
                    <span style={theme.spo.product.iconHeadTrash}>
                      {
                        props.store.CartReducers.webSpoProductInCart.filter(
                          (c) =>
                            c.idProduct !==
                            props.store.AppReducers.SpoShopCfg?.articleExpense //"SP001"
                        ).length
                      }{" "}
                      pz
                    </span>
                  ) : (
                    <span style={theme.spo.product.iconHeadTrash}>
                      <FormattedNumber
                        value={roundTwoDigit(calculateTotalCart())}
                        style="currency"
                        currency="EUR"
                        currencySign="accounting"
                      />
                    </span>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    textAlign: "right",
                    paddingRight: theme.spo.pSm,
                  }}
                >
                  {props.store.AppReducers &&
                    props.store.AppReducers.SpoShopCfg && (
                      <div style={theme.spo.product.textExpirationCart}>
                        Il carrello scadrà alle{" "}
                        {moment(props.store.CartReducers.dateCreate)
                          .add(
                            props.store.CartReducers.idDeliveryModeNavigation
                              .maxOrderConfirmTime,
                            "m"
                          )
                          .format("HH:mm")}
                      </div>
                    )}
                </Grid>

                <Grid
                  container
                  style={{
                    marginTop: theme.spo.mSm,
                    marginLeft: theme.spo.mXs,
                  }}
                >
                  <Grid item xs={11}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      style={theme.spo.product.textBoxSearch}
                    >
                      <InputLabel htmlFor="standard-adornment-password">
                        Ricerca
                      </InputLabel>
                      <OutlinedInput
                        style={{ borderRadius: "50px" }}
                        label="Ricerca"
                        value={textSearch}
                        onChange={(e) => {
                          if (typingTimeout.idTimeOut) {
                            clearTimeout(typingTimeout.idTimeOut);
                          }
                          textValueCurrent = e.target.value;
                          setTypingTimeout({
                            value: e.target.value,
                            idTimeOut: setTimeout(() => {
                              // changeText(typingTimeout.value);
                              changeText(textValueCurrent);
                            }, 2000),
                          });
                          setTextSearch(e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setTextSearch("");
                                changeText("");
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            }
            rowBar={
              <Grid container justify="center" style={{ textAlign: "center" }}>
                {listDepartment &&
                  listDepartment.map &&
                  listDepartment.length > 1 && (
                    <>
                      <Grid
                        id="list-department-grid-container-small"
                        item
                        xs={12}
                        className={classes.showOnSm}
                      >
                        <FormControl fullWidth>
                          <InputLabel id="department-select-label">
                            Scegli il reparto
                          </InputLabel>
                          <Select
                            style={{ marginRight: theme.spo.pSm }}
                            // className={}
                            labelId="department-select-label"
                            id="department-select"
                            value={department.idCatWeb}
                            onChange={(e) => {
                              var sel = listDepartment.filter((c) => {
                                return c.idCatWeb == e.target.value;
                              });
                              handleClickDepartment(sel[0], e);
                            }}
                          >
                            {listDepartment &&
                              listDepartment.map((c, i) => {
                                return (
                                  <MenuItem
                                    value={c.idCatWeb}
                                    key={"list-department-menu" + c.idCatWeb}
                                  >
                                    {c.desCatWeb}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid
                        id="list-department-grid-container-large"
                        item
                        xs={12}
                        className={classes.shoOnMd}
                      >
                        {listDepartment &&
                          listDepartment.map &&
                          listDepartment.map((c, i) => {
                            return (
                              <Button
                                id={"list-department-btn" + c.idCatWeb}
                                key={"list-department-btn" + c.idCatWeb}
                                style={{
                                  ...(department &&
                                  department.idCatWeb == c.idCatWeb
                                    ? theme.spo.product.departmentSelected
                                    : theme.spo.product.departmentUnSelected),
                                  display: "inline",
                                  padding: 0,
                                }}
                                className={classes.buttomDepartment}
                                variant="outlined"
                                onClick={(e) => {
                                  handleClickDepartment(c, e);
                                }}
                              >
                                <div>
                                  <CustomIcon
                                    dbCode={
                                      department &&
                                      department.idCatWeb == c.idCatWeb
                                        ? "DepartmentSel" + i
                                        : "Department" + i
                                    }
                                    breakpoints={{ xs: "6" }}
                                    center={true}
                                    icon={
                                      <FontAwesomeIcon
                                        style={{
                                          ...(department &&
                                          department.idCatWeb == c.idCatWeb
                                            ? theme.spo.product
                                                .IconDeparmentSelected
                                            : theme.spo.product.IconDeparment),
                                        }}
                                        icon={["fas", c.icon]}
                                      />
                                    }
                                  ></CustomIcon>
                                </div>
                                <div>{c.desCatWeb}</div>
                              </Button>
                            );
                          })}
                        <Menu
                          PopoverClasses={{ paper: classes.popoverPaper }}
                          id="menu-category-department"
                          anchorEl={refDepartment.current}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          // transformOrigin={{
                          //   vertical: "bottom",
                          //   horizontal: "left",
                          // }}
                        >
                          <Grid id="container-menu-category" container>
                            <Grid
                              id="title-icon-menu-category"
                              item
                              xs={4}
                              sm={2}
                              style={theme.spo.product.departmentPopLeft}
                            >
                              <h3>{departmentFirstSelection.desCatWeb}</h3>
                              <div>
                                <FontAwesomeIcon
                                  style={{
                                    color: "#fff",
                                    fontSize: "50px",
                                    marginBottom: theme.spo.mMd,
                                  }}
                                  icon={["fas", departmentFirstSelection.icon]}
                                />
                              </div>
                            </Grid>
                            <Grid
                              id="list-menu-category"
                              item
                              xs={8}
                              sm={10}
                              style={{ padding: theme.spo.mSm }}
                            >
                              <Grid container>
                                {menuListCategory &&
                                  menuListCategory.category &&
                                  menuListCategory.category.map &&
                                  menuListCategory.category.map((c) => {
                                    return (
                                      // <MenuItem
                                      //   key={"menu-list-cat" + c.idCatWeb}
                                      //   onClick={() => {
                                      //     clickMenuCategory(c);
                                      //   }}
                                      // >
                                      //   {c.desCatWeb}
                                      // </MenuItem>
                                      <Grid item xs="12" sm="4">
                                        <Button
                                          id={"button-list-cat" + c.idCatWeb}
                                          style={{
                                            justifyContent: "flex-start",
                                            textAlign: "left",
                                            fontSize: "12px",
                                          }}
                                          fullWidth
                                          key={"menu-list-cat" + c.idCatWeb}
                                          onClick={() => {
                                            clickMenuCategory(c);
                                          }}
                                        >
                                          {c.desCatWeb}
                                        </Button>
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Menu>
                      </Grid>
                      <div
                        id="refMenuDepartment"
                        ref={refDepartment}
                        style={{ width: "100%" }}
                      ></div>
                    </>
                  )}
              </Grid>
            }
          >
            <Viewport
              viewEnable={{
                left: process.env.REACT_APP_HIDE_LEFT_VIEWPORT != "true",
                right: true,
              }}
              // viewEnable={{ right: true }}
              iconLeft={<FontAwesomeIcon icon={faListAlt} />}
              collapsedLeft={collapsedLeft}
              contentLeft={
                <ContentLeftBar
                  current={currentIdArea}
                  idCategory={idCategory}
                  listUser={shopListUser}
                  setListUser={setShopListUser}
                  brand={brand}
                  setBrand={setBrand}
                  listBrand={listBrand}
                  listCategory={listCategory}
                  onChangeSelectCategory={onChangeSelectCategory}
                  onClickNewList={handleClickNewList}
                  onClickDelList={fetchUserRemoveShopList}
                  onClickToCartList={handleClickAddToCartList}
                ></ContentLeftBar>
              }
              iconRight={<FontAwesomeIcon icon={faEye} />}
              contentRight={
                // <Box style={{ padding: theme.spo.pSm }}>
                <Grid container justify="flex-start" spacing={2}>
                  {props.store.CartReducers.webSpoProductInCart &&
                  props.store.CartReducers.webSpoProductInCart.length > 0 ? (
                    <>
                      {props.store.CartReducers.webSpoProductInCart
                        .filter(
                          (c) =>
                            c.idProduct !==
                            props.store.AppReducers.SpoShopCfg?.articleExpense
                        ) //"SP001")
                        .map((e, i) => {
                          return (
                            <CardProduct
                              currentIdArea={currentIdArea}
                              gridSize={{ xs: 12 }}
                              // key={
                              //   "card-product-" +
                              //   e.vwWebSpoProduct.coD_RIORD +
                              //   "-" +
                              //   i
                              // }
                              product={e.vwWebSpoProduct}
                              newProduct={fetchAddToCart}
                              removeProduct={fetchRemoveFromCart}
                              updateQuantity={fetcUpdateQuantityProduct}
                              cart={props.store.CartReducers}
                              setFavorite={fetchSetFavorite}
                              favorites={listFavorite}
                              setShopList={handleClickAddToShopList}
                              shopList={shopListUser.listProduct}
                            ></CardProduct>
                          );
                        })}
                    </>
                  ) : (
                    <div
                      style={{
                        padding: theme.spo.pSm,
                      }}
                    >
                      Nessun prodotto nel carrello
                    </div>
                  )}
                </Grid>
                // </Box>
              }
              forceDrawerRight={true}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: theme.spo.product.backgroundPage,
                }}
              >
                <Grid container style={theme.spo.product.headerListProduct}>
                  <Grid item xs={5} md={2}>
                    <Box
                      style={{
                        paddingLeft: theme.spo.pSm,
                        paddingTop: theme.spo.pSm,
                      }}
                    >
                      <Box display="flex" flexDirection="row">
                        <Box
                          style={{
                            marginRight: theme.spo.mSm,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setLayoutCard(true);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faThLarge}
                              style={{
                                fontSize:
                                  theme.spo.product.sizeIconDisplayProduct,
                                color:
                                  theme.spo.product.backgroundColorIconPrimary,
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Box className={classes.shoOnMd}>
                          <IconButton
                            onClick={() => {
                              setLayoutCard(false);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faList}
                              style={{
                                fontSize:
                                  theme.spo.product.sizeIconDisplayProduct,
                                color:
                                  theme.spo.product.backgroundColorIconPrimary,
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={1} md={7}>
                    <Grid
                      container
                      // xs={12}
                      style={{
                        marginTop: theme.spo.mSm,
                      }}
                      justify="center"
                    >
                      {/* Tab menu */}
                      <Tabs
                        className={classes.tabSectionProduct}
                        value={currentValueTab}
                        onChange={(event, value) => {
                          setCurrentValueTab(value);
                        }}
                        style={{
                          // width: "650px",
                          width: "100%",
                          color: theme.spo.darkGray,
                        }}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons="on"
                      >
                        <Tab
                          // label={"Prodotti"}
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faTags} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {productLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(0);
                          }}
                        />
                        <Tab
                          //label="Preferiti"
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faHeart} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {favoriteLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(1);
                          }}
                        />
                        <Tab
                          // label="Liste"
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faBook} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {listLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(4);
                          }}
                        />
                        {process.env.REACT_APP_SHOW_PROMOTION === "true" && (
                          <Tab
                            // label="Promozioni"
                            icon={
                              <div>
                                <FontAwesomeIcon icon={faPercent} />
                                <span style={{ paddingLeft: theme.spo.pXs }}>
                                  {promoLabel}
                                </span>
                              </div>
                            }
                            onClick={() => {
                              onChangeTypeOfSearch(3);
                            }}
                          />
                        )}
                        <Tab
                          //label="Acquistati da te"
                          icon={
                            <div>
                              <FontAwesomeIcon icon={faShoppingBag} />
                              <span style={{ paddingLeft: theme.spo.pXs }}>
                                {buyLabel}
                              </span>
                            </div>
                          }
                          onClick={() => {
                            onChangeTypeOfSearch(2);
                          }}
                        />
                      </Tabs>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={3}
                    style={{
                      paddingRight: theme.spo.mSm,
                      textAlign: "right",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="orderby-select-label">
                        Ordina per
                      </InputLabel>
                      <Select
                        // className={}
                        labelId="orderby-select-label"
                        id="category-select"
                        value={orderBySelect}
                        onChange={(e) => {
                          setOrderBySelect(e.target.value);
                          setOrderBy(orderByObject[e.target.value]);
                        }}
                      >
                        {orderByObject.map((c, i) => {
                          return (
                            <MenuItem
                              key={"order-by" + Math.floor(Math.random() * 999)}
                              value={i}
                            >
                              {c.description}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={theme.spo.product.breadcrumbBar}
                  >
                    <Grid item xs={6} style={{ marginTop: "10px" }}>
                      <Box>
                        <h6 style={{ margin: 0 }}>
                          {currentTitle}
                          {department?.desCatWeb &&
                            " > " + department.desCatWeb}
                          {category?.desCatWeb && " > " + category.desCatWeb}
                        </h6>
                      </Box>
                    </Grid>
                    <Grid item container xs={6} style={{ textAlign: "right" }}>
                      <Grid
                        item
                        xs={8}
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          paddingTop: "5px",
                          paddingRight: theme.spo.pSm,
                        }}
                      >
                        Lista della spesa selezionata:
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <Select
                            label={"Lista della spesa selezionata"}
                            style={{ backgroundColor: "#fff" }}
                            // className={}
                            labelId="shopList-select-label"
                            id="shopList-select"
                            value={shopListUser.selected}
                            onChange={(e) => {
                              setShopListUser({
                                ...shopListUser,
                                selected: e.target.value,
                              });
                            }}
                            startAdornment={
                              <InputAdornment
                                className={classes.selectAdornment}
                                position="start"
                              >
                                <IconButton
                                  onClick={() => {
                                    setShopListUser({
                                      ...shopListUser,
                                      openDialogNewList: true,
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </IconButton>
                              </InputAdornment>
                            }
                          >
                            {shopListUser.list &&
                              [
                                { id: "", description: "Nessuna" },
                                ...shopListUser.list,
                              ].map((c, i) => {
                                return (
                                  <MenuItem
                                    key={
                                      "order-by" +
                                      Math.floor(Math.random() * 999)
                                    }
                                    value={c.id}
                                  >
                                    {c.description}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText></FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="flex-start"
                  spacing={2}
                  style={
                    {
                      //paddingTop: theme.spo.pMd,
                    }
                  }
                >
                  {listProduct &&
                    listProduct.map &&
                    listProduct.map((c, i) => {
                      return (
                        // <Grid container style={{ margin: theme.spo.mSm }}>

                        layoutCard ? (
                          <CardProduct
                            // key={"card-product-" + c.coD_RIORD + "-" + i}
                            currentIdArea={currentIdArea}
                            product={c}
                            newProduct={fetchAddToCart}
                            removeProduct={fetchRemoveFromCart}
                            updateQuantity={fetcUpdateQuantityProduct}
                            cart={props.store.CartReducers}
                            setFavorite={fetchSetFavorite}
                            favorites={listFavorite}
                            setShopList={handleClickAddToShopList}
                            shopList={shopListUser.listProduct}
                          ></CardProduct>
                        ) : (
                          <GridProduct
                            // key={"card-product-" + c.coD_RIORD + "-" + i}
                            product={c}
                            newProduct={fetchAddToCart}
                            removeProduct={fetchRemoveFromCart}
                            updateQuantity={fetcUpdateQuantityProduct}
                            cart={props.store.CartReducers}
                            setFavorite={fetchSetFavorite}
                            favorites={listFavorite}
                          ></GridProduct>
                        )
                      );
                    })}
                  {(!listProduct || !listProduct.length) && (
                    <Grid item xs={12}>
                      <Paper
                        style={{ padding: theme.spo.pSm, lineHeight: "40px" }}
                      >
                        {tabInfo[currentIdArea] &&
                          tabInfo[currentIdArea].emptyInfo}
                      </Paper>
                    </Grid>
                  )}
                </Grid>
                {altriRisultati && (
                  <Grid
                    container
                    justify={"center"}
                    style={{ margin: theme.spo.mMd }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSearchFilterProduct({
                          ...searchFilterProduct,
                          ...{
                            pageNumber: searchFilterProduct.pageNumber + 1,
                          },
                        });
                      }}
                    >
                      Altri Risultati
                    </Button>
                  </Grid>
                )}
                {!loadingProduct && (
                  <ScrollTop>
                    <Fab
                      color="secondary"
                      size="small"
                      aria-label="scroll back to top"
                      style={{
                        position: "fixed",
                        bottom: theme.spacing(2),
                        right: theme.spacing(2),
                      }}
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </ScrollTop>
                )}
              </Grid>
            </Viewport>
            {/* <div
                className={
                  !collapsedLeft
                    ? classes.widthLeft
                    : classes.widthLeftCollapsed
                }
                style={{
                  marginRight: theme.spo.mSm - 5,
                  transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                }}
              >
                <Grid item xs={12}>
                  <Paper
                    style={{
                      borderTopRightRadius: "25px",
                      borderBottomRightRadius: "25px",
                    }}
                  >
                    <Box className={classes.hideExpandBarMd}>
                      <IconButton
                        color="primary"
                        aria-label="menu laterale"
                        onClick={() => {
                          setCollapsedLeft(!collapsedLeft);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowsAltH} />
                      </IconButton>
                    </Box>
                    <Box className={classes.hideExpandBarXs}>
                      <IconButton
                        color="primary"
                        aria-label="menu laterale"
                        onClick={toggleDrawer("left", true)}
                        // style={{ position: "fixed" }}
                      >
                        <FontAwesomeIcon icon={faArrowsAltH} />
                      </IconButton>
                    </Box>
                    <Drawer
                      anchor={"left"}
                      open={drawer["left"]}
                      onClose={toggleDrawer("left", false)}
                    >
                      <div
                        className={classes.drawerWidth}
                        role="presentation"
                        // onClick={toggleDrawer("left", false)}
                        onKeyDown={toggleDrawer("left", false)}
                      ></div>
                    </Drawer>
                    <Slide
                      direction="right"
                      in={!collapsedLeft}
                      mountOnEnter
                      unmountOnExit
                      // timeout={{ appear: 5000, enter: 100, exit: 100 }}
                    >
                      <div className={classes.hideExpandBarMd}>
                        <ContentLeftBar
                          idCategory={idCategory}
                          brand={brand}
                          setBrand={setBrand}
                          listBrand={listBrand}
                          listCategory={listCategory}
                          onChangeSelectCategory={onChangeSelectCategory}
                        ></ContentLeftBar>
                      </div>
                    </Slide>
                  </Paper>
                </Grid>
              </div>

              <Grid
                container
                className={
                  !collapsedLeft ? classes.widthRightFull : classes.widthRight
                }
                spacing={3}
                style={{
                  //width: sizeRight,
                  transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                }}
              ></Grid> */}
          </LayoutWithHead>

          <Footer></Footer>
        </div>
      )}
      <DialogWithContent
        open={shopListUser.openDialogNewList}
        onClose={() => {
          setShopListUser({ ...shopListUser, openDialogNewList: false });
        }}
        title={"Lista della spesa"}
        contentText={"Scrivi il nome della lista che vuoi creare"}
        content={
          <TextField
            value={shopListUser.nameNewList}
            onChange={(e) => {
              setShopListUser({ ...shopListUser, nameNewList: e.target.value });
            }}
          ></TextField>
        }
        action={[
          {
            onClick: () => {
              setShopListUser({ ...shopListUser, openDialogNewList: false });
            },
            label: "Annulla",
          },
          {
            onClick: () => {
              fetchListUserAdd();
            },
            label: "Ok",
          },
        ]}
      ></DialogWithContent>
      {/* productQuantity: 1,
        openDialogProductQuantity: null, */}
      <DialogWithContent
        open={shopListUser.openDialogProductQuantity}
        onClose={() => {
          setShopListUser({
            ...shopListUser,
            openDialogProductQuantity: null,
          });
        }}
        title={"Lista della spesa"}
        contentText={
          "Specifica la quantità che vuoi inserire nella lista della spesa"
        }
        content={
          <ProductCounter
            readOnly={false}
            product={shopListUser.openDialogProductQuantity}
            setCount={(c) => {
              setShopListUser({
                ...shopListUser,
                productQuantity: c,
              });
            }}
            count={shopListUser.productQuantity}
            allowZero={false}
            updateQuantity={(product, count) => {}}
          ></ProductCounter>
        }
        action={[
          {
            onClick: () => {
              setShopListUser({
                ...shopListUser,
                openDialogProductQuantity: null,
              });
            },
            label: "Annulla",
          },
          {
            onClick: () => {
              fetchAddToShopList();
            },
            label: "Ok",
          },
        ]}
      ></DialogWithContent>
      <DialogListShopList
        showState={shopListUser.dialogSelectShopList}
        setShowState={(state) => {
          setShopListUser({ ...shopListUser, dialogSelectShopList: state });
        }}
        helperText={"Inserisci il nome della tua lista della spesa"}
        elementList={shopListUser.list}
        hideNew={true}
        confirm={(selected) => {
          shopListUser.selected = selected;
          setShopListUser({ ...shopListUser });
          return false;
        }}
        undo={() => {}}
      ></DialogListShopList>
      <DialogWithContent
        open={shopListUser.dialogAddToCartQuestion}
        onClose={() => {
          setShopListUser({
            ...shopListUser,
            dialogAddToCartQuestion: null,
          });
        }}
        title={"Prodotti da aggiungere"}
        contentText={""}
        content={
          <InternalComponentDialogAddCart
            cart={
              props.store.CartReducers
                ? props.store.CartReducers.webSpoProductInCart
                : null
            }
            shopListUser={shopListUser}
            fetchAddToCartList={fetchAddListToCart}
            setShopListUser={setShopListUser}
          ></InternalComponentDialogAddCart>
        }
        action={[
          {
            onClick: () => {
              setShopListUser({
                ...shopListUser,
                dialogAddToCartQuestion: null,
              });
            },
            label: "Annulla",
          },
        ]}
      ></DialogWithContent>
      <DialogListShopList
        showState={shopListUser.dialogSelectShopList}
        setShowState={(state) => {
          setShopListUser({ ...shopListUser, dialogSelectShopList: state });
        }}
        helperText={"Inserisci il nome della tua lista della spesa"}
        elementList={shopListUser.list}
        hideNew={true}
        confirm={(selected) => {
          shopListUser.selected = selected;
          setShopListUser({ ...shopListUser });
          return false;
        }}
        undo={() => {}}
      ></DialogListShopList>
      <DialogWithContent
        open={dialogProductNotInAssortiment}
        onClose={() => {
          setDialogProductNotInAssortiment(null);
        }}
        title={"Attenzione prodotti non in assortimento"}
        contentText={
          "Questi prodotti non sono più in assortimento e non saranno aggiunti al carrello"
        }
        content={
          <>
            {dialogProductNotInAssortiment &&
              dialogProductNotInAssortiment.map((c) => {
                return (
                  <>
                    <div>{c.description}</div>
                  </>
                );
              })}
          </>
        }
        action={[
          {
            onClick: () => {
              setDialogProductNotInAssortiment(null);
            },
            label: "Ok",
          },
        ]}
      ></DialogWithContent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};

export default connect(mapStateToProps, {
  SaveStateApp,
  SetInfoCart,
  fetchCfgShopSpo,
  setStatusUserShopList,
})(Product);

// export default Product;
const InternalComponentDialogAddCart = (props) => {
  const props_cart = props.cart;
  const props_shopListUser = props.shopListUser;
  const props_setShopListUser = props.setShopListUser;
  const props_fetchAddToCartList = props.fetchAddToCartList;

  var id = props_shopListUser.dialogAddToCartQuestion
    ? props_shopListUser.dialogAddToCartQuestion.id
    : null;

  const [replaceState, setReplaceState] = React.useState({
    replace: null,
    productState: undefined,
  });

  useEffect(() => {
    if (replaceState.replace === null) return;
    //se l'utente ha chiesto di sovrascrivere il carrello eseguo l'inserimento , di default viene usato il sovrascrivi
    if (replaceState.replace == true) {
      props_fetchAddToCartList(id);
      props_setShopListUser({
        ...props_shopListUser,
        dialogAddToCartQuestion: null,
      });
      return;
    }
    //verifico se ci sono prodotti presenti sia nel carrello che nella lista, se non ci sono non devo fare altre domande ma
    // utilizzare la replace action null vengono uniti i carrelli
    var existInCart = props_cart.filter(
      (c) =>
        props_shopListUser.listProduct.filter((d) => d.coD_RIORD == c.idProduct)
          .length > 0
    );
    if (existInCart.length === 0) {
      props_fetchAddToCartList(id, null);
      props_setShopListUser({
        ...props_shopListUser,
        dialogAddToCartQuestion: null,
      });
      return;
    }
    //existInCart.length != 0
    setReplaceState({ ...replaceState, productState: null });
  }, [replaceState.replace]);

  useEffect(() => {
    if (
      replaceState.productState === null ||
      replaceState.productState === undefined
    )
      return;
    props_fetchAddToCartList(id, replaceState.productState);
    props_setShopListUser({
      ...props_shopListUser,
      dialogAddToCartQuestion: null,
    });
  }, [replaceState.productState]);

  return (
    <div>
      {replaceState.replace === null &&
        replaceState.productState === undefined && (
          <div>
            <div>
              Hai dei prodotti nel carrello vuoi mantenere gli articoli
              presenti?
            </div>
            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setReplaceState({ ...replaceState, replace: false });
                }}
              >
                Aggiungi al carrello i prodotti della lista
              </Button>
            </div>
            <div style={{ paddingBottom: "10px" }}>
              <Button
                style={{ width: "100%" }}
                variant="contained"
                onClick={() => {
                  setReplaceState({ ...replaceState, replace: true });
                }}
              >
                Svuota il carrello e aggiungi i prodotti della lista
              </Button>
            </div>
          </div>
        )}
      {/* di default è undefined , null quando deve essere renderizzato */}
      {replaceState.productState === null && (
        <div>
          <div>Ci sono degli articoli presenti nel carrello e nella lista?</div>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Button
              style={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                setReplaceState({ ...replaceState, productState: 0 });
              }}
            >
              Mantieni le quantità del tuo carrello
            </Button>
          </div>
          <div style={{ paddingBottom: "10px" }}>
            <Button
              style={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                setReplaceState({ ...replaceState, productState: 2 });
              }}
            >
              Mantieni le quantità della lista
            </Button>
          </div>
          <div style={{ paddingBottom: "10px" }}>
            <Button
              style={{ width: "100%" }}
              variant="contained"
              onClick={() => {
                setReplaceState({ ...replaceState, productState: 1 });
              }}
            >
              Somma le due quantità
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
