import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import DialogWithContent from "../DialogWithContent";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";

const SellerConditions = (props) => {
  const props_checked = props.checked;
  const props_setAccepted = props.setAccepted;
  const props_errorUnchecked = props.errorUnchecked || false;

  const [infoOpen, setInfoOpen] = React.useState(false);

  return (
    <div>
      <FormControl
        required
        error={props_errorUnchecked && props_checked == false}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={props_checked}
              onChange={(event) => {
                props_setAccepted(event.target.checked);
              }}
              name={"sellerConditions"}
            />
          }
          label={
            <div>
              Ho letto e accettato le{" "}
              <Link
                href="#"
                onClick={() => {
                  setInfoOpen(true);
                }}
              >
                condizioni di vendita
              </Link>
            </div>
          }
        />
        <FormHelperText>
          {props_errorUnchecked && props_checked == false
            ? "Per proseguire devi accettare le condizioni di vendita"
            : ""}{" "}
        </FormHelperText>
      </FormControl>
      {/* <div>
        Do il consenso al trattamento dei dati per attività di profilazione
      </div> */}
      <div>
        {/* <Link
        href="#"
        onClick={() => {
          setInfoOpen(true);
        }}>
          Leggi l' informativa sulla Privacy
        </Link> */}
      </div>
      <DialogWithContent
        open={infoOpen}
        onClose={() => {
          setInfoOpen(false);
        }}
        contentText={
          <div
            dangerouslySetInnerHTML={{
              __html: props.store.AppReducers.SpoCfg.sellingConditions,
            }}
          ></div>
        }
        title="Condizioni di vendita"
        action={[
          {
            onClick: () => {
              setInfoOpen(false);
            },
            label: "Chiudi",
          },
        ]}
      ></DialogWithContent>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { store: state };
};
export default connect(mapStateToProps, {})(SellerConditions);
