import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import { roundTwoDigit } from "../../utility/utility";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";

import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import { FormattedNumber } from "react-intl";
import ProductCounter from "./ProductCounter";

const useStyles = makeStyles((theme) => ({
  titleDiv: {
    // height: "120px",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      height: "100px",
    },
  },
  promoDiv: {
    // height: "130px",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
      height: "35px",
    },
  },
}));

const CardProduct = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const props_product = props.product;
  const props_cart = props.cart;
  const props_noCounter =
    props.noCounter == null || props.noCounter == undefined
      ? false
      : props.noCounter;

  const props_noFavorite =
    props.noFavorite == null || props.noFavorite == undefined
      ? false
      : props.noFavorite;

  const props_newProduct = props.newProduct || function () {};
  const props_removeProduct = props.removeProduct || function () {};
  const props_updateQuantity = props.updateQuantity || function () {};
  const props_setFavorite = props.setFavorite || function () {};
  const props_isFavorite = props.isFavorite;

  const [count, setCount] = React.useState(0);

  useEffect(() => {
    if (!props_product) return;
    if (
      props_cart &&
      props_cart.webSpoProductInCart &&
      props_cart.webSpoProductInCart.filter
    ) {
      let objInCart = props_cart.webSpoProductInCart.filter((c) => {
        return c.idProduct == props_product.coD_RIORD;
      });
      if (objInCart && objInCart[0]) {
        setCount(objInCart[0].quantity);
      } else {
        setCount(0);
      }
      return;
    }
    setCount(0);
  }, [props_product]);

  const handleClickFavorite = () => {
    props_setFavorite(props_product);
  };

  const isFavoriteLocal = (product) => {
    return props_isFavorite;
  };

  return (
    <Grid container id={`product-detail-${props_product.coD_RIORD}`}>
      <Grid item xs={12}>
        <Grid container>
          <div className={classes.titleDiv} style={{ width: "100%" }}>
            <Grid item xs={12}>
              <div style={{ textAlign: "right" }}>
                {!props_noFavorite && (
                  <IconButton
                    color="primary"
                    aria-label="Aggiungi ai preferiti"
                    onClick={handleClickFavorite}
                    style={{ padding: 0 }}
                  >
                    {isFavoriteLocal(props_product) ? (
                      //|| props_product.isFavorite
                      <FavoriteIcon></FavoriteIcon>
                    ) : (
                      <FavoriteBorderIcon></FavoriteBorderIcon>
                    )}
                  </IconButton>
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: theme.spo.product.card.sizeCategoryText,
                color: theme.spo.product.card.colorCategory,
              }}
            >
              {props_product.category}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: theme.spo.product.card.sizeDescriptionText,
                color: theme.spo.product.card.colorDescription,
                fontWeight: "bold",
              }}
            >
              {props_product.description}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: theme.spo.product.card.sizeBrandText,
                color: theme.spo.product.card.colorBrand,
              }}
            >
              {props_product.marca}
            </Grid>
          </div>
          <Grid
            container
            className={classes.promoDiv}
            style={{
              // height: "130px",
              width: "100%",
              marginTop: theme.spo.mSm,
            }}
          >
            <Grid item xs={3}>
              {props_product.promoPrice > 0 && (
                <div>
                  {/* <div style={{ fontSize: "14px" }}>Promozione</div> */}
                  <div
                    style={{
                      backgroundColor: "#ff0000",
                      color: "#ffffff",
                      textAlign: "center",
                      fontSize: "23px",
                    }}
                  >
                    -
                    {Math.round(
                      100 -
                        (100 * props_product.promoPrice) / props_product.price
                    )}
                    %
                  </div>
                </div>
              )}{" "}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              {" "}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {props_product.promoPrice > 0 ? (
                <div
                  style={{
                    textDecoration: "line-through",
                    color: "#ff0000",
                  }}
                >
                  <FormattedNumber
                    value={roundTwoDigit(props_product.price).toFixed(2)}
                    style="currency"
                    currency="EUR"
                    currencySign="accounting"
                  />
                </div>
              ) : (
                <div>&nbsp; </div>
              )}
              {/*prChiaroPeso se 0 non viene visualizzato i lprezzo chiaro */}
              <Box style={{ fontSize: "12px", height: "14px" }}>
                {props_product.prChiaroPeso != 0 && (
                  <div>
                    {/*se PrChiaroPeso != 0 
	                      2decimal arrotondamento
	                      Streing(Math.round(Price/PrChiaroPeso,2)) +  " "+ PrChiaroDes (string) */}
                    <FormattedNumber
                      value={roundTwoDigit(
                        (props_product.promoPrice || props_product.price) /
                          props_product.prChiaroPeso
                      ).toFixed(2)}
                      style="currency"
                      currency="EUR"
                      currencySign="accounting"
                    />{" "}
                    {props_product.prChiaroDes}
                  </div>
                )}
              </Box>
              <div
                style={{
                  fontSize: theme.spo.product.card.sizePriceText,
                  color: theme.spo.product.card.colorPrice,
                  fontWeight: "bold",
                }}
              >
                <FormattedNumber
                  value={roundTwoDigit(
                    (props_product.promoPrice || props_product.price).toFixed(2)
                  )}
                  style="currency"
                  currency="EUR"
                  currencySign="accounting"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                // textAlign: "right",
                paddingTop: theme.spo.mSm,
                paddingBottom: theme.spo.mSm,
              }}
            >
              {props_product?.assortimento?.toLowerCase() !== "s" && (
                <>Non disponibile</>
              )}
              {props_product?.assortimento?.toLowerCase() === "s" && (
                <>
                  {" "}
                  {count <= 0 && (
                    <Box>
                      <Box
                        style={{
                          paddingRight: theme.spo.mSm,
                          fontSize: "12px",
                          height: "14px",
                        }}
                      >
                        {props_product.unmis &&
                          props_product.unmis.toLowerCase() == "kg" &&
                          props_product.unmis}
                      </Box>
                      <Button
                        variant="contained"
                        disabled={!props.cart}
                        color="primary"
                        aria-label="add to shopping cart"
                        onClick={() => {
                          if (!props_noCounter) {
                            setCount(props_product.qtaBase);
                          }
                          props_newProduct(props_product);
                        }}
                        startIcon={<FontAwesomeIcon icon={faCartPlus} />}
                      >
                        aggiungi al carrello
                      </Button>
                    </Box>
                  )}
                  {count > 0 && (
                    <div>
                      <ProductCounter
                        readOnly={false}
                        product={props_product}
                        setCount={setCount}
                        count={count}
                        removeProduct={props_removeProduct}
                        updateQuantity={props_updateQuantity}
                        allowZero={true}
                      ></ProductCounter>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(CardProduct);
