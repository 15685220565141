import { GET, POST, PUT, DELETE } from "request-helper";

import validationInvoice from "./WizardUserValidation";
import DialogConfirm from "../components/DialogConfirm";
import DialogWithContent from "../components/DialogWithContent";
import Footer from "../components/Footer";
import InvoiceData from "../components/Invoice/InvoiceData";
import DialogAddress from "../components/Address/DialogAddress";

import {
  geocodeAddress,
  parseAddressComponents,
} from "../components/geocodeAddress";
import Logo from "../components/Logo";

import React, { useEffect } from "react";

import { Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faPlus,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

import { NotificationManager } from "react-notifications";
import LayoutWithHead from "../components/LayoutWithHead";

var timeoutSearchAddres = -1;

const useStyles = makeStyles((theme) => ({}));

const GestioneAccount = (props) => {
  const theme = useTheme();

  const [userData, setUserData] = React.useState({
    name: "",
    surname: "",
    mobile: "",
    reminderExpiration: 0,
    invoiceBusinessName: "",
    invoiceVATNumber: "",
    fiscalCode: "",
    invoiceAddress: "",
    invoiceCity: "",
    invoiceZipCode: "",
    invoiceProvince: "",
    invoiceVATPrefix: "",
    invoicePECMailAddress: "",
    invoiceSDICode: "",
  });

  const [flagReminder, setFlagReminder] = React.useState(false);

  const [haveInvoice, setHaveInvoice] = React.useState(false);

  const [addressList, setAddressList] = React.useState(null);

  const [flagAddress, setFlagAddress] = React.useState(null);
  const [flagMakeSaveAddress, setFlagMakeSaveAddress] = React.useState(null);

  const [address, setAddress] = React.useState({
    address: "",
    city: "",
    postalCode: "",
    province: "",
  });
  const [geocodedAddress, setGeocodedAddress] = React.useState(null);
  const [geocoding, setGeocoding] = React.useState(null);

  const [notSaved, setNotSAved] = React.useState(false);
  const [dialogNotSaved, setDialogNotSAved] = React.useState(false);

  const [allCountry, setAllCountry] = React.useState([]);
  // const [errorFiscalCode, setErrorFiscalCode] = React.useState(false);
  // const [errorVatNumber, setErrorVatNumber] = React.useState(false);

  useEffect(() => {
    fetchListAddress();
    fetchUserInfo();
    fetchAllcountry();
  }, []);

  useEffect(() => {
    if (userData.reminderExpiration && userData.reminderExpiration > 0) {
      setFlagReminder(true);
    }
  }, [userData]);

  useEffect(() => {
    // if (
    //   address.address &&
    //   address.city &&
    //   address.postalCode &&
    //   address.province
    // )
    //   timeoutFetchAddress();
  }, [address]);

  useEffect(() => {
    if (geocodedAddress && flagMakeSaveAddress) {
      saveAddress();
    }
  }, [geocodedAddress, flagMakeSaveAddress]);

  const fetchUserInfo = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/User/GetUserInformation",
      null,
      null,
      true
    ).then((data) => {
      setUserData(data);
      if (data.invoiceBusinessName) {
        setHaveInvoice(true);
      }
    });
  };

  // const fetchCheckVat = () => {
  //   GET(
  //     process.env.REACT_APP_URL_API_SPO + "v1/User/CheckFiscalCode",
  //     null,
  //     { invoiceVATNumber: userData.invoiceVATNumber },
  //     true
  //   ).then((data) => {
  //     setErrorVatNumber(data);
  //   });
  // };

  // const fetchCheckFiscalCode = () => {
  //   GET(
  //     process.env.REACT_APP_URL_API_SPO + "v1/User/CheckVatCode",
  //     null,
  //     { fiscalCode: userData.fiscalCode },
  //     true
  //   ).then((data) => {
  //     setErrorFiscalCode(data);
  //   });
  // };

  const fetchAllcountry = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/User/AllCountry",
      null,
      null,
      true
    ).then((data) => {
      setAllCountry(data);
    });
  };

  const fetchListAddress = () => {
    GET(
      process.env.REACT_APP_URL_API_SPO + "v1/User/ListAddressByUser",
      null,
      null,
      true
    ).then((data) => {
      if (data.length == 0) {
        setAddressList(null);
        return;
      }
      setAddressList(data);
    });
  };

  const timeoutFetchAddress = () => {
    clearTimeout(timeoutSearchAddres);
    timeoutSearchAddres = setTimeout(() => {
      fetchGeocodeAddress();
    }, 2000);
  };

  const fetchGeocodeAddress = () => {
    let fullAddress =
      address.address +
      " " +
      address.city +
      " " +
      address.postalCode +
      " " +
      address.province;

    geocodeAddress(fullAddress, (data) => {
      setGeocodedAddress(data);
    });
  };

  const parseAddress = (type, short) => {
    let addressComponents = geocodedAddress[0].address_components;
    return parseAddressComponents(addressComponents, type, short);
  };

  const fetchUpdateAddress = (model) => {
    PUT(
      process.env.REACT_APP_URL_API_SPO + "v1/User/UpdateAddress",
      model,
      null,
      true
    ).then((data) => {
      fetchListAddress();
    });
  };
  const fetchAddAddress = (model) => {
    POST(
      process.env.REACT_APP_URL_API_SPO + "v1/User/AddAddress",
      model,
      null,
      true
    ).then((data) => {
      fetchListAddress();
    });
  };

  const fetchDeleteAddress = (id) => {
    DELETE(
      process.env.REACT_APP_URL_API_SPO +
        "v1/User/RemoveAddress?idAddress=" +
        id,
      null,
      null,
      true
    ).then((data) => {
      fetchListAddress();
    });
  };
  const fetchUpdateUserInfo = (userDataParam) => {
    if (addressList && addressList.length > 0 && !userData.mobile) {
      NotificationManager.error(
        "Per le prenotazioni con consegna a domicilio è obbligatorio il numero di cellulare"
      );
      return;
    }
    PUT(
      process.env.REACT_APP_URL_API_SPO + "v1/User/UpdateUserInformation",
      userDataParam || userData,
      null,
      true
    ).then((data) => {
      setNotSAved(false);
      NotificationManager.success("Salvataggio completato");
    });
  };

  const saveAddress = () => {
    let _address = parseAddress("route", false);
    let _streetNumber = parseAddress("street_number", false);
    if (_streetNumber) _address = _address + ", " + _streetNumber;
    let _locality = parseAddress("administrative_area_level_3", false);
    let _prov = parseAddress("administrative_area_level_2", true);
    let _postalCode = parseAddress("postal_code", false);

    let addressmodel = {
      Id: address.id,
      NoteAddress: address.noteAddress,
      Address: _address || address.address,
      City: _locality || address.city,
      PostalCode: _postalCode || address.postalCode,
      Province: _prov || address.province,
      Lat:
        geocodedAddress[0] &&
        geocodedAddress[0].geometry &&
        geocodedAddress[0].geometry.location &&
        geocodedAddress[0].geometry.location.lat,
      Lon:
        geocodedAddress[0] &&
        geocodedAddress[0].geometry &&
        geocodedAddress[0].geometry.location &&
        geocodedAddress[0].geometry.location.lng,
    };
    if (!addressmodel.Id) {
      fetchAddAddress(addressmodel);
    } else {
      fetchUpdateAddress(addressmodel);
    }
  };

  const editAddress = (item) => {
    setAddress(item);
  };
  const newAddress = (item) => {
    setAddress({});
  };

  const backToProduct = () => {
    props.history.push(
      process.env.REACT_APP_BASE_PATH_ROUTE + "/" + props.match.params.backTo
    );
  };

  return (
    <div>
      <LayoutWithHead
        rowLogoLeft={<Logo></Logo>}
        rowBar={
          <>
            <Grid item xs={6}>
              <Tooltip title="Torna agli acquisti">
                <IconButton
                  style={{ fontSize: "45px", padding: 0 }}
                  color="primary"
                  onClick={() => {
                    if (notSaved) {
                      setDialogNotSAved(true);
                    } else {
                      backToProduct();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Button
                style={{ marginRight: theme.spo.mSm }}
                variant="contained"
                color="primary"
                onClick={() => {
                  var objValidate = validationInvoice(haveInvoice, userData);
                  if (haveInvoice) {
                    for (var valid in objValidate) {
                      if (
                        objValidate[valid].required == true &&
                        !userData[valid]
                      ) {
                        NotificationManager.error(
                          "Devi inserire tutti i dati obbligatori prima di proseguire"
                        );
                        return;
                      }
                    }
                    for (var valid in objValidate) {
                      var v =
                        typeof objValidate[valid].error == "function"
                          ? objValidate[valid].error()
                          : objValidate[valid].error == true;
                      if (v) {
                        NotificationManager.error(
                          "Dati non validi , prima di proseguire verificarne la correttezza"
                        );
                        return;
                      }
                    }
                  } else {
                    for (var valid in objValidate) {
                      userData[valid] = "";
                    }
                    //se gli oggetti sono disabilitati non aggiorna il valore , quindi abilito la fatturazione
                    //modifico i valori e la disabilito
                    setHaveInvoice(true);
                    setUserData(userData);
                    setTimeout(() => {
                      setHaveInvoice(false);
                    }, 250);
                  }

                  fetchUpdateUserInfo(userData);
                }}
              >
                Aggiorna
              </Button>
            </Grid>
          </>
        }
      >
        <div>
          <Paper style={{ marginTop: "35px", padding: "10px" }}>
            <Grid container>
              <Grid item xs={12} md={3}>
                {userData.fidelityCardCode && (
                  <Grid item xs={12} className={"pb-md"}>
                    <TextField
                      disabled={true}
                      required={false}
                      //error={!userData.fidelityCardCod}
                      style={{ width: "100%" }}
                      label="FidelityCard"
                      value={userData.fidelityCardCode}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          ...{ fidelityCardCode: e.target.value },
                        });
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} className={"pb-md"}>
                  <TextField
                    //   disabled={haveTessera}
                    required={true}
                    error={!userData.name}
                    style={{ width: "100%" }}
                    label="Nome"
                    value={userData.name}
                    onChange={(e) => {
                      setUserData({ ...userData, ...{ name: e.target.value } });
                      setNotSAved(true);
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={"pb-md"}>
                  <TextField
                    //   disabled={haveTessera}
                    required={true}
                    error={!userData.surname}
                    style={{ width: "100%" }}
                    label="Cognome"
                    value={userData.surname}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        ...{ surname: e.target.value },
                      });
                      setNotSAved(true);
                    }}
                  />
                </Grid>

                <Grid item xs={12} className={"pb-md"}>
                  <TextField
                    //   disabled={haveTessera}
                    error={
                      addressList && addressList.length > 0 && !userData.mobile
                    }
                    style={{ width: "100%" }}
                    label="Cellulare"
                    value={userData.mobile}
                    onChange={(e) => {
                      setUserData({
                        ...userData,
                        ...{ mobile: e.target.value },
                      });
                      setNotSAved(true);
                    }}
                    helperText={
                      userData.mobile ||
                      "Numero di cellulare, obbligatorio in caso di consegna a domicilio"
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <h3>Dati di fatturazione</h3>
                  </Grid>
                  <Grid item xs={12}>
                    <InvoiceData
                      haveInvoice={haveInvoice}
                      setHaveInvoice={setHaveInvoice}
                      userData={userData}
                      setUserData={setUserData}
                      setNotSaved={setNotSAved}
                      allCountry={allCountry}
                    ></InvoiceData>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <h3>Indirizzi</h3>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setFlagAddress(true);
                        newAddress();
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                  </Grid>
                </Grid>
                <Paper className={"mb-md"}>
                  {addressList &&
                    addressList.map((item) => {
                      return (
                        <List>
                          <ListItem>
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setFlagAddress(true);
                                editAddress(item);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </IconButton>

                            <DialogConfirm
                              title={"Elimina"}
                              text={
                                "Sei sicuro di voler eliminare l'indirizzo?"
                              }
                            >
                              {(confirm) => (
                                <IconButton
                                  color="primary"
                                  onClick={confirm(() => {
                                    fetchDeleteAddress(item.id);
                                  })}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </IconButton>
                              )}
                            </DialogConfirm>
                            <ListItemText
                              primary={
                                item.address +
                                " " +
                                item.city +
                                " " +
                                item.postalCode +
                                " " +
                                item.province
                              }
                            />
                          </ListItem>
                        </List>
                      );
                    })}
                </Paper>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      checked={flagReminder}
                      control={<Checkbox color="primary" />}
                      label="Invia email di promemoria prima dello scadere della prenotazione"
                      labelPlacement="end"
                      onChange={(e) => {
                        setFlagReminder(e.target.checked);
                        if (!e.target.checked) {
                          setUserData({
                            ...userData,
                            ...{ reminderExpiration: 0 },
                          });
                        }
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Select
                      className={"ml-md"}
                      style={{ width: "100%" }}
                      disabled={!flagReminder}
                      value={userData.reminderExpiration}
                      labelId="reminder-select-label"
                      id="reminder-select"
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          ...{ reminderExpiration: e.target.value },
                        });
                      }}
                    >
                      <MenuItem value={0}>Mai</MenuItem>;
                      <MenuItem value={30}>30 min</MenuItem>;
                      <MenuItem value={60}>1 ora</MenuItem>;
                      <MenuItem value={120}>2 ore</MenuItem>;
                    </Select>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <DialogAddress
            showDialog={flagAddress}
            hideDialog={() => {
              setFlagAddress(false);
            }}
            title={"Nuovo indirizzo"}
            saveAddress={() => {
              setFlagAddress(false);
              setFlagMakeSaveAddress(true);
            }}
            geocodedAddress={(data) => {
              setGeocodedAddress(data);
            }}
          ></DialogAddress>
          {/* <Dialog
            open={flagAddress}
            onClose={() => {
              setFlagAddress(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle id="alert-dialog-title">
              {"Nuovo indirizzo"}
            </DialogTitle>
            <DialogContent>
              <div style={{}}>
                
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      required={true}
                      error={!address.address}
                      style={{ width: "100%" }}
                      label="Indirizzo"
                      value={address.address}
                      onChange={(e) => {
                        setAddress({
                          ...address,
                          ...{ address: e.target.value },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required={true}
                      error={!address.city}
                      style={{ width: "100%" }}
                      label="Citta"
                      value={address.city}
                      onChange={(e) => {
                        setAddress({ ...address, ...{ city: e.target.value } });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required={true}
                      error={!address.postalCode}
                      style={{ width: "100%" }}
                      label="Cap"
                      value={address.postalCode}
                      onChange={(e) => {
                        setAddress({
                          ...address,
                          ...{ postalCode: e.target.value },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required={true}
                      error={!address.province}
                      style={{ width: "100%" }}
                      label="Provincia"
                      value={address.province}
                      onChange={(e) => {
                        setAddress({
                          ...address,
                          ...{ province: e.target.value },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Note ( note aggiuntive per la consegna es: numero interno ,numero scala o piano etc...   )"
                      value={address.noteAddress}
                      onChange={(e) => {
                        setAddress({
                          ...address,
                          ...{ noteAddress: e.target.value },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setFlagAddress(false);
                }}
                color="primary"
              >
                Annulla
              </Button>

              <DialogConfirm
                title={"Conferma indirizzo"}
                text={
                  <div>
                    <div>Hai selezionato il seguente indirizzo </div>
                    <div>
                      <div style={{ textAlign: "center", fontSize: "18px" }}>
                        {geocodedAddress &&
                          geocodedAddress[0].formatted_address}
                      </div>
                    </div>
                    <div>Vuoi confermarlo?</div>
                  </div>
                }
              >
                {(confirm) => (
                  <Button
                    onClick={(e) => {
                      fetchGeocodeAddress();
                      confirm(() => {
                        setFlagAddress(false);
                        setFlagMakeSaveAddress(true);
                        // saveAddress();
                      })(e);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Salva
                  </Button>
                )}
              </DialogConfirm>
            </DialogActions>
          </Dialog> */}

          <DialogWithContent
            key={"DialogNotSaved"}
            open={dialogNotSaved}
            onClose={() => {
              setDialogNotSAved(false);
            }}
            contentText={
              <div>
                <div>
                  Ci sono delle modifiche non salvate. Se prosegui , le
                  modifiche andranno perse
                </div>
                <div>Vuoi proseguire?</div>
              </div>
            }
            title={"Modifiche non salvate"}
            action={[
              {
                onClick: () => {
                  setDialogNotSAved(false);
                },
                label: "Annulla",
              },
              {
                onClick: () => {
                  backToProduct();
                },
                label: "Prosegui",
              },
            ]}
          ></DialogWithContent>
        </div>
      </LayoutWithHead>
      <Footer></Footer>
    </div>
  );
};

export default GestioneAccount;
