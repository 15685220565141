import React, { useState, useEffect, useContext } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Moment from "react-moment";

import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStoreAlt,
  faCalendarAlt,
  faMapMarkedAlt,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  mainBodyGrid: {
    marginTop: theme.spo.mSm,
  },
  hideDeliverySummary: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const DeliverySummary = (props) => {
  const props_deliveryDetail = props.deliveryDetail;
  const classes = useStyles();
  const theme = useTheme();

  const redirectToWizardCart = () => {
    props.history.push(process.env.REACT_APP_BASE_PATH_ROUTE + "/WizardCart");
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={8}
      lg={9}
      style={{
        textAlign: "left",
        overflow: "hidden",
        paddingLeft: theme.spo.mXs,
      }}
      className={classes.hideDeliverySummary}
    >
      {/* modalita consegna */}
      {props_deliveryDetail && (
        <Box
          display="flex"
          flexDirection="row"
          style={{
            paddingTop: theme.spo.pSm,
            color: theme.spo.product.colorFirstRowHead,
          }}
        >
          <Box
            id="BoxTimeDelivery"
            style={{
              paddingRight: theme.spo.pXs,
            }}
          >
            <div>
              <Button
                style={{
                  //width: "220px",
                  ...theme.spo.product.deliverySummary,
                }}
                startIcon={
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    style={{ fontSize: "14px" }}
                  ></FontAwesomeIcon>
                }
                onClick={() => {
                  redirectToWizardCart();
                }}
              >
                <Moment format="DD-MM-YYYY">
                  {props_deliveryDetail.dateDelivery}
                </Moment>{" "}
                <Moment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                  {props_deliveryDetail.timeStart}
                </Moment>{" "}
                -{" "}
                <Moment parse="YYYY-MM-DD HH:mm" format="HH:mm">
                  {props_deliveryDetail.timeEnd}
                </Moment>
              </Button>
            </div>
          </Box>
          <Box
            id="BoxShopInfo"
            style={{
              paddingRight: theme.spo.pXs,
            }}
          >
            <div>
              <Button
                style={{
                  //width: "200px",
                  ...theme.spo.product.deliverySummary,
                }}
                startIcon={
                  <FontAwesomeIcon
                    icon={faStoreAlt}
                    style={{ fontSize: "14px" }}
                  ></FontAwesomeIcon>
                }
                onClick={() => {
                  redirectToWizardCart();
                }}
              >
                {/* {deliveryDetail.shopInfo}</div> */}
                {props_deliveryDetail.shopAddress}
              </Button>
            </div>
          </Box>

          {props_deliveryDetail.deliveryAddress && (
            <Box
              id="BoxDeliveryAddress"
              style={{
                paddingRight: theme.spo.pXs,
              }}
            >
              <div>
                <Button
                  style={{
                    //width: "200px",
                    ...theme.spo.product.deliverySummary,
                  }}
                  startIcon={
                    <FontAwesomeIcon
                      icon={faMapMarkedAlt}
                      style={{ fontSize: "14px" }}
                    ></FontAwesomeIcon>
                  }
                  onClick={() => {
                    redirectToWizardCart();
                  }}
                >
                  {props_deliveryDetail.deliveryAddress}
                </Button>
              </div>
            </Box>
          )}
          <Box
            id="BoxDeliveryMode"
            style={{
              paddingRight: theme.spo.pXs,
            }}
          >
            <div>
              <Button
                style={{
                  //width: "100px",
                  ...theme.spo.product.deliverySummary,
                }}
                startIcon={
                  <FontAwesomeIcon
                    icon={faShoppingBag}
                    style={{ fontSize: "14px" }}
                  ></FontAwesomeIcon>
                }
                onClick={() => {
                  redirectToWizardCart();
                }}
              >
                {props_deliveryDetail.deliveryMode}
              </Button>
            </div>
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default withRouter(DeliverySummary);
