import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { SetInfoCart, SaveStateApp } from "../redux/actions";

import Layout from "../components/Layout";
import DialogLoader from "../components/DialogLoader";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Logo from "../components/Logo";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
    margin: 0,
  },
  subTitle: {
    color: theme.palette.text.secondary,
  },
}));

const SpoLayoutBg = (props) => {
  const props_titleHeadText = props.titleHeadText;
  const props_mainContainer = props.mainContainer;
  const props_marginSeparator = props.marginSeparator || 0;
  // const props_titleSecondaryHeadText = props.titleSecondaryHeadText;
  const props_secondaryContainer = props.secondaryContainer;
  const props_bottomTitle = props.bottomTitle;
  const props_bottomSubTitle = props.bottomSubtitle;
  const props_codeImage = props.codeImage;

  const [fullLoaded, setFullLoaded] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const getBackgroundImage = () => {
    var image = null;
    if (props_codeImage) {
      var imgArray = props.store.AppReducers.SpoCfg.webSpoParamShopImage.filter(
        (c) => c.code == props_codeImage
      );
      if (imgArray && imgArray.length > 0) {
        image = imgArray[0].url;
      } else {
        image = props.store.AppReducers.SpoCfg.backgroundImage;
      }
    } else {
      image = props.store.AppReducers.SpoCfg.backgroundImage;
    }

    var fullPath =
      image.indexOf("http") == 0
        ? image
        : process.env.REACT_APP_BASE_PATH_ROUTE + "/" + image;
    return "url(" + fullPath + ")";
  };

  return (
    <Layout
      fullLoaded={() => {
        setFullLoaded(true);
      }}
    >
      {fullLoaded &&
      props.store.AppReducers &&
      props.store.AppReducers.SpoCfg ? (
        <div>
          {
            <div
              style={{
                // background: `url(${bckImg}) no-repeat top center fixed`,
                background:
                  getBackgroundImage() + " center top / cover no-repeat fixed",
                // backgroundSize: "contain",
                //backgroundSize: "cover",
                minHeight: "40pc",
              }}
            >
              <div>
                <Grid container justify="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ marginTop: "40px" }}
                  >
                    <Paper
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        ...(theme.spo.spoLayoutBg.paperWithOpacity || {}),
                      }}
                    >
                      <Grid container style={{ position: "relative" }}>
                        <Grid item xs={8} lg={10}>
                          <Box p={3}>
                            <h2 className={classes.title}>
                              {props_titleHeadText}
                            </h2>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          lg={2}
                          style={theme.spo.spoLayoutBg.logoContainer}
                        >
                          <Logo></Logo>
                        </Grid>
                      </Grid>

                      <Box p={3}>{props_mainContainer}</Box>
                    </Paper>
                    {props_secondaryContainer && (
                      <Paper
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                          marginTop: props_marginSeparator,
                          ...(theme.spo.spoLayoutBg.paperWithOpacity || {}),
                        }}
                      >
                        {/* <Box p={3}>
                    <h2 className={classes.title}>
                      {props_titleSecondaryHeadText}
                    </h2>
                  </Box> */}
                        <Box p={3}>{props_secondaryContainer}</Box>
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          }
          {(props_bottomTitle || props_bottomSubTitle) && (
            <div>
              <Grid container justify="center">
                <Grid item xs={8}>
                  <Box ml={3}>
                    <div>
                      <h2 className={classes.title}>{props_bottomTitle}</h2>

                      <h4 className={classes.subTitle}>
                        {props_bottomSubTitle}
                      </h4>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
          {props.children}
        </div>
      ) : (
        <DialogLoader show={true}></DialogLoader>
      )}
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return { store: state };
};

export default connect(mapStateToProps, {
  // SetInfoCart,
  // SaveStateApp,
})(SpoLayoutBg);
