export const loginConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  response_type: "code",
  scope: process.env.REACT_APP_SCOPE,
  // post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  extraQueryParams: {
    customer: process.env.REACT_APP_CUSTOMER,
    urlCustomization: "",
    //   process.env.REACT_APP_URL_API_SPO + "v1/Config/LoginCustomization",
    urlContact: "",
    canRegister: process.env.REACT_APP_CAN_REGISTER,
    logoutUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    envData: "",
    shopEmail: "",
    shopTel: "",
  },
};

//props.store?.AppReducers
export const extendedLoginConfig = (appReducers) => {
  return {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: "code",
    scope: process.env.REACT_APP_SCOPE,
    // post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    extraQueryParams: {
      customer: process.env.REACT_APP_CUSTOMER,
      urlCustomization: "",
      //   process.env.REACT_APP_URL_API_SPO + "v1/Config/LoginCustomization",
      urlContact: "",
      canRegister: process.env.REACT_APP_CAN_REGISTER,
      logoutUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
      envData: true,
      shopEmail: appReducers?.SpoCfg?.emailCustomerService,
      shopTel: appReducers?.SpoCfg?.phoneCustomerService,
    },
  };
};
